import React, { useEffect, useState } from "react";
import messageInput from "../../assets/gifs/tutorial/message_input.gif";
import selectedAssets from "../../assets/gifs/tutorial/select_assets.gif";
import close from "../../assets/icons/closeChat.svg";

const TutorialScreen = ({ setFirstTime }) => {
  return (
    <div className="fixed flex justify-center items-center h-screen w-screen top-0 left-0 backdrop-blur-sm">
      <div className="h-fit w-2/3 bg-[#1f1e25e5] rounded-3xl p-8">
        <div
          className="flex justify-end pb-4"
          onClick={() => {
            setFirstTime(false);
          }}
        >
          <img
            className="cursor-pointer hover:scale-110 transition-all duration-150 ease-linear"
            src={close}
          ></img>
        </div>
        <div className="flex justify-around items-start px-[3%] mb-3">
          <div className="flex h-full w-1/2 flex-col items-center border-r-[1px] border-[#e2e8f017]">
            <div className="flex w-[300px] h-[200px]">
              <img
                className="rounded-2xl"
                src={messageInput}
                width="300px"
                height="200px"
              ></img>
            </div>
            <div className="h-1/2 px-[10%]">
              <p className="text-white font-mono font-extrabold mt-5">
                Select an asset, open the text editor, and unleash your
                creativity by typing any of the following commands:
              </p>
              <p className="text-white font-mono mt-3">
                <span className="text-orange-400">/create:</span> To create
                something new.
              </p>
              <p className="text-white font-mono">
                <span className="text-orange-400">/modify:</span> To make
                changes.
              </p>
              <p className="text-white font-mono">
                <span className="text-orange-400">/change:</span> For
                alterations
              </p>
            </div>
          </div>
          <div className="flex h-full w-1/2 flex-col items-center">
            <div className="flex w-[300px] h-[200px]">
              <img
                className="rounded-2xl"
                src={selectedAssets}
                width="300px"
                height="200px"
              ></img>
            </div>
            <div className="h-1/2 px-[10%]">
              <p className="text-white font-mono font-extrabold mt-5">
                To replace an asset:
              </p>
              <p className="text-white font-mono mt-3">
                Click on the asset you want.
              </p>
              <p className="text-white font-mono">
                Drag it over the existing asset that needs to be replaced.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialScreen;
