import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/userSlice";
import creditSlice from "./features/creditSlice";
import systemSlice from "./features/systemSlice";
import gameSlice from "./features/gameSlice";
import gameDisplaySlice from "./features/gameDisplaySlice";
import imageAssetsSlice from "./features/imageAssetsSlice";
import soundAssetsSlice from "./features/soundAssetsSlice";
export default configureStore({
  reducer: {
    system: systemSlice,
    user: userSlice,
    credit: creditSlice,
    game: gameSlice,
    imageAssets: imageAssetsSlice,
    soundAssets: soundAssetsSlice,
    gameDisplay: gameDisplaySlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["system/updateUploadedImage"], // Add your action type here
      },
    }),
});
