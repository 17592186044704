import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DisplayGame from "./DisplayGame";
import helpIcon from "../../assets/icons/help_icon.svg";

import {
  updateCurrentURL,
  updateGameDisplayCode,
} from "../../store/features/systemSlice";
import {
  updateBackgroundSplash,
  updateCode,
} from "../../store/features/gameSlice";
import { tabletIcon } from "../../assets";
import convertToAssetDict from "../../utils/convertToAssetsLoader";
import ChatBar from "./ChatBar";
import NewHeader from "../../components/NewHeader";
import Sidebar from "./Sidebar";
import posthog from "posthog-js";
import { posthogCapture } from "../../utils/posthogCapture";
import { Fade, Tooltip } from "@mui/material";
import expandIcon from "../../assets/icons/fullscreen_icon.svg";
import getImageFromAssetList from "../../utils/getImageFromAssetList";
import removePatternsFromCode from "../../utils/removePatternsFromCode";
import {
  regexToRemoveAssetsLoader,
  regexToRemoveSoundsLoader,
} from "../../constants";
import useCreateGameCode from "../../hooks/useCreateGameCode";


const GameRender = ({ setFirstTime }) => {
  const createGameCode = useCreateGameCode();
  const isLoading = useSelector((state) => state.system.isLoading);
  const imageAssets = useSelector((state) => state.imageAssets.imageAssets);
  const soundAssets = useSelector((state) => state.soundAssets.soundAssets);
  const code = useSelector((state) => state.game.code);
  const assets_list = useSelector((state) => state.game.assets_list);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [gameDisplaySize, setGameDisplaySize] = useState({
    width: 880,
    height: 495,
  });
  const [isViewChartOpen, setIsViewChartOpen] = useState(false);
  const apiError = useSelector((state) => state.system.gameGenerationAPIError);
  const displayMode = useSelector((state) => state.game.displayMode);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickPreviewBtn = () => {
    posthogCapture("PREVIEW_BUTTON_CLICKED");
    navigate("/preview");
  };

  const onClickHelpBtn = () => {
    setFirstTime(true);
  };

  useEffect(() => {
    if (!isLoading) {
      posthogCapture("GAME_SCREEN_VIEWED");
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(updateCurrentURL(window.location.pathname));
    const updateWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < 1120 && screenWidth >= 768) {
      setGameDisplaySize({
        width: 444,
        height: 250,
      });
    } else if (screenWidth < 1200 && screenWidth >= 1024) {
      setGameDisplaySize({
        width: 498,
        height: 280,
      });
    } else if (screenWidth < 1320 && screenWidth >= 1024) {
      setGameDisplaySize({
        width: 622,
        height: 350,
      });
    } else {
      setGameDisplaySize({
        width: 800,
        height: 450,
      });
    }
  }, [screenWidth]);

  useEffect(() => {
    // console.log({ imageAssets });
    createGameCode(imageAssets, soundAssets, code);
  }, [imageAssets, soundAssets]);

  return (
    <>
      <div className="h-screen pt-[8px]">
        <NewHeader enableLaunch />
        <div className="flex flex-row justify-between px-3 pt-3 pb-2 bg-[#131619] h-[90%]">
          <div className="flex flex-col justify-between items-center w-[65%] h-full">
            <div className="h-full flex items-center">
              <div
                className={`overflow-hidden rounded-[23px] aspect-video`}
                style={{
                  width: `${displayMode ? gameDisplaySize.width : "281.25"}px`,
                  height: `${displayMode ? gameDisplaySize.height : "500"}px`,
                }}
              >
                <DisplayGame
                  screenSize={
                    displayMode
                      ? gameDisplaySize
                      : {
                          width: 281.25,
                          height: 500,
                        }
                  }
                />
              </div>
            </div>
            <div
              className="flex items-center justify-between"
              style={{
                width: `${gameDisplaySize.width}px`,
              }}
            >
              {apiError && <p className="text-red-500 ">{apiError}</p>}
            </div>
            <div className="w-full px-6 flex justify-between">
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Help"
                placement="top-end"
              >
                <button
                  onClick={onClickHelpBtn}
                  className="flex items-center rounded-[8px] p-2 font-semibold text-white gradient-dark mb-3 transition-all	ease-linear duration-200 hover:scale-110"
                >
                  <img src={helpIcon} width="30px" alt="fullscreen" />
                </button>
              </Tooltip>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Fullscreen"
                placement="top-end"
              >
                <button
                  onClick={onClickPreviewBtn}
                  className="flex items-center rounded-[8px] py-3 px-4 font-semibold text-white gradient-dark mb-3 transition-all	ease-linear duration-200 hover:scale-110"
                >
                  <img src={expandIcon} width="20px" alt="fullscreen" />
                </button>
              </Tooltip>
            </div>
            <ChatBar setIsViewChartOpen={setIsViewChartOpen} />
            {/* {!isViewChartOpen ? (
              <ChatBar setIsViewChartOpen={setIsViewChartOpen} />
            ) : (
              <>
                <ChatHistory setIsViewChartOpen={setIsViewChartOpen} />
              </>
            )} */}
          </div>
          <Sidebar assets_list={assets_list} />
        </div>
      </div>
    </>
  );
};

export default GameRender;
