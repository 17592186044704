import React from "react";
import { default as shootingStar } from "../../assets/image/shooting-star-background.png";
import { default as errorImage } from "../../assets/image/error-image.svg";
import { useNavigate } from "react-router";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(`/`);
  };

  return (
    <div
      className="flex items-center justify-center h-screen relative"
      style={{
        backgroundImage: `url(${shootingStar})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container grid grid-cols-2 gap-8 mx-auto relative">
        <div className="flex items-center justify-center col-span-1">
          <div className="text-white">
            <h1 className="mb-4 text-6xl py-4">Oops...</h1>
            <p className="mb-8 text-4xl">Page Not Found</p>
            <p className="mb-8 text-xl">
              This Page doesn't exist or was removed! <br />
              We suggest you back to home.
            </p>
            <button
              className={
                "px-4 py-2 text-white rounded-md flex gap-3 items-center w-60 justify-center font-bold bg-gradient-to-br to-[#6049E6] from-[#3416AC]"
              }
              onClick={goBack}
            >
              <i className="font-bold">←</i> Back To Home
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center col-span-1">
          <img src={errorImage} alt="Error" className="w-80" />
        </div>
      </div>
      <div className="absolute bottom-0 right-0">
        <div
          className="triangle"
          style={{
            borderLeft: "calc(65vw) solid transparent",
            borderBottom: "35vh solid #1F1E25",
          }}
        ></div>
      </div>
    </div>
  );
};

export default PageNotFound;
