import { useDispatch } from "react-redux";
import convertToAssetsLoader from "../utils/convertToAssetsLoader";
import {
  regexToRemoveAssetsLoader,
  regexToRemoveSoundsLoader,
} from "../constants";
import removePatternsFromCode from "../utils/removePatternsFromCode";
import getImageFromAssetList from "../utils/getImageFromAssetList";
import {
  updateBackgroundSplash,
  updateCode,
} from "../store/features/gameSlice";
import { updateGameDisplayCode } from "../store/features/systemSlice";

const useCreateGameCode = () => {
  const dispatch = useDispatch();

  const createGameCode = async (imageAssets, soundAssets, gameCode) => {
    let splashImage = getImageFromAssetList(imageAssets, "splash");
    splashImage = splashImage ? splashImage : "/default_background.jpg";
    dispatch(updateBackgroundSplash(splashImage));

    // console.log({ imageAssets });
    let assetsLoader = convertToAssetsLoader(imageAssets);
    const strigifiedAssetsLoader = JSON.stringify(assetsLoader);
    assetsLoader = `let assetsLoader = ${strigifiedAssetsLoader} \n\n`;

    let soundsLoader = convertToAssetsLoader(soundAssets);
    const strigifiedSoundsLoader = JSON.stringify(soundsLoader);
    soundsLoader = `let soundsLoader = ${strigifiedSoundsLoader} \n\n`;

    const regexPatterns = [
      regexToRemoveAssetsLoader,
      regexToRemoveSoundsLoader,
    ];
    const assetRemovedCode = removePatternsFromCode(gameCode, regexPatterns);
    // console.log(assetRemovedCode);
    const updatedCode =
      assetsLoader + soundsLoader + assetRemovedCode.trimStart();
    dispatch(updateCode(updatedCode));
    dispatch(updateGameDisplayCode(updatedCode));
  };

  return createGameCode;
};

export default useCreateGameCode;
