import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlayAudio from "../../assets/icons/PlayAudio.svg";
import PauseAudio from "../../assets/icons/PauseAudio.svg";
import { assetLoaderGif } from "../../assets";
import { updateSelectedAsset } from "../../store/features/systemSlice";

const SoundAssets = () => {
  const soundAssets = useSelector((state) => state.soundAssets.soundAssets);
  return (
    <div className="flex flex-wrap">
      {soundAssets.map((asset) => (
        <AudioAsset asset={asset} key={asset.url} />
      ))}
    </div>
  );
};

const AudioAsset = ({ asset }) => {
  const selectedAsset = useSelector((state) => state.system.selectedAsset);
  const isAssetLoading = useSelector((state) => state.system.isAssetLoading);
  const [selectedAssetLoading, setSelectedAssetLoading] = useState("");

  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const audioRef = useRef(null);

  const dispatch = useDispatch();

  const onClickSound = useCallback(() => {
    if (asset.id === selectedAsset?.id) {
      dispatch(updateSelectedAsset(null));
    } else {
      dispatch(updateSelectedAsset(asset));
    }
  }, [dispatch, asset, selectedAsset]);

  const toggleAudio = useCallback(
    (e) => {
      e.stopPropagation();
      if (!isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    },
    [isPlaying]
  );

  const handleAudioEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    if (!isAssetLoading && selectedAsset) {
      setSelectedAssetLoading(selectedAsset.id);
    }
  }, [selectedAsset, isAssetLoading]);

  return (
    <div
      class="w-full sm:w-1/2 md:w-1/3 text-center p-4"
      onClick={onClickSound}
    >
      <div
        className={`bg-gray-800 p-3 rounded-md text-center w-28 h-25 relative overflow-hidden border-[4px] transition-all duration-100 ease-linear   ${
          selectedAsset?.id === asset.id
            ? "border-[#2E40DE]"
            : "border-[#0D0F10]"
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {asset.category === undefined ? (
          <div>
            <img
              src={PlayAudio}
              alt="Audio Asset"
              className="cursor-pointer mx-auto w-16 h-16"
            />
            <div
              className={`text-sm mt-2 text-white`}
              style={{ whiteSpace: "nowrap" }}
            >
              {"..."}
            </div>
          </div>
        ) : (
          <div>
            <img
              src={
                isAssetLoading && selectedAssetLoading === asset.id
                  ? assetLoaderGif
                  : isPlaying
                    ? PauseAudio
                    : PlayAudio
              }
              alt="Audio Asset"
              className="cursor-pointer mx-auto w-16 h-16"
              onClick={toggleAudio}
            />

            <div
              className={`text-sm mt-2 text-white ${
                isHovered ? "animate-marquee" : ""
              }`}
              style={{ whiteSpace: "nowrap" }}
            >
              {asset.category}
            </div>
            <audio ref={audioRef} src={asset.url} onEnded={handleAudioEnded} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundAssets;
