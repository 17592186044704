// import { useDispatch, useSelector } from "react-redux";
// import { deleteAsset, updateAssets_list } from "../../store/features/gameSlice";
// import {
//   addDeletedAssets,
//   updateSelectedAsset,
// } from "../../store/features/systemSlice";
// import { assetLoaderGif, deleteIcon } from "../../assets";
// import Image from "../../utils/Image";
// import { posthogCapture } from "../../utils/posthogCapture";
// import { Fade, Tooltip } from "@mui/material";
// import { useEffect, useState } from "react";

// const DisplayAssets = () => {
//   let imageAssets = useSelector((state) => state.imageAssets.imageAssets);
//   const selectedAsset = useSelector((state) => state.system.selectedAsset);
//   const isAssetLoading = useSelector((state) => state.system.isAssetLoading);
//   const dispatch = useDispatch();
//   const [selectedAssetLoading, setSelectedAssetLoading] = useState("");

//   // imageAssets = imageAssets.length > 0 ? imageAssets : [];

//   const onClickImage = (asset) => {
//     if (asset.id === selectedAsset.id) {
//       dispatch(updateSelectedAsset(""));
//     } else {
//       dispatch(updateSelectedAsset(asset));
//     }
//   };

//   useEffect(() => {
//     if (!isAssetLoading) {
//       setSelectedAssetLoading(selectedAsset.id);
//     }
//   }, [selectedAsset]);

//   const handleOnDrag = (e, dragAsset) => {
//     const dragAssetString = JSON.stringify(dragAsset);
//     e.dataTransfer.setData("dragAsset", dragAssetString);
//   };

//   const handleOnDrop = (e, dropAsset) => {
//     let dragAssetString = e.dataTransfer.getData("dragAsset");
//     let dragAsset = JSON.parse(dragAssetString);
//     // console.log(assetImg);
//     // console.log("the asset at drop is", dropAsset);
//     // console.log(dragAsset.url);
//     let updatedAssets_List = imageAssets.map((eachAsset) => {
//       if (dropAsset.id === eachAsset.id) {
//         return {
//           ...dragAsset,
//           id: eachAsset.id,
//           name: eachAsset.name,
//         };
//       } else if (dragAsset.id === eachAsset.id) {
//         return {
//           ...dropAsset,
//           id: eachAsset.id,
//           name: eachAsset.name,
//         };
//       } else {
//         return eachAsset;
//       }
//     });

//     posthogCapture("ASSET_DRAG_AND_DROPPED", {
//       new_asset: dragAsset,
//       old_asset: dropAsset,
//     });
//     dispatch(updateAssets_list(updatedAssets_List));
//     dispatch(updateSelectedAsset(""));
//     // console.log({ updatedAssets_List });
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };
//   // console.log({ imageAssets });

//   return (
//     <div className="grid grid-cols-3 gap-4 mx-4 mb-5">
//       {imageAssets.map((asset) => (
//         <div key={asset.id}>
//           {isAssetLoading && selectedAssetLoading === asset.id ? (
//             <div className="w-24 h-24 flex items-center justify-between border-[4px] border-[#2E40DE] mt-3 rounded-md">
//               <img src={assetLoaderGif} className="w-10 h-10" alt="loader" />
//             </div>
//           ) : (
//             <>
//               <div
//                 className={`relative group h-40 p-1 bg-[#1E2026] rounded-md flex flex-col justify-around items-center ${
//                   selectedAsset.id === asset.id
//                     ? "border-[4px] border-[#2E40DE]"
//                     : ""
//                 } transition-all duration-100 ease-linear`}
//                 id="imgDiv"
//                 onClick={() => onClickImage(asset)}
//                 draggable
//                 onDragStart={(e) =>
//                   handleOnDrag(e, {
//                     ...asset,
//                   })
//                 } //
//                 onDrop={(e) =>
//                   handleOnDrop(e, {
//                     ...asset,
//                   })
//                 }
//                 onDragOver={handleDragOver}
//               >
//                 {/* <DeleteAssetBtn imageID={asset.id} deletedAsset={asset} />
//                 <Image
//                   src={asset.blobURL}
//                   classNames={`w-24 h-24 rounded-md box-border`}
//                   alt="asset"
//                   isSelected={asset.isSelected}
//                 />
//                 <p className="text-white text-xs">
//                   {asset.name.length > 15
//                     ? asset.name.substring(0, 15) + "..."
//                     : asset.name}
//                 </p>
//                 <Tooltip
//                   TransitionComponent={Fade}
//                   TransitionProps={{ timeout: 600 }}
//                   title={asset.name}
//                   placement="top"
//                   arrow
//                 >
//                   <DeleteAssetBtn imageID={asset.id} deletedAsset={asset} />
//                   <Image
//                     src={asset.url}
//                     classNames={`w-24 h-24 rounded-[23px] mt-3 box-border backgroun-red-500 ${
//                       selectedAsset.id === asset.id
//                         ? "border-[4px] border-[#2E40DE]"
//                         : ""
//                     } transition-all duration-100 ease-linear`}
//                     alt="asset"
//                     isSelected={asset.isSelected}
//                   />
//                 </Tooltip> */}
//               </div>
//             </>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default DisplayAssets;

// // const HoverName = ({ keyName }) => {
// //   return (
// //     <div className="absolute left-0 right-0 items-center justify-center hidden duration-500 -top-12 group-hover:flex">
// //       <div className="bg-[#181C20] rounded-md py-2 px-5 shadow shadow-gray-800">
// //         <div>
// //           <h1 className="mt-1 text-sm text-[#9B9C9E]">{keyName}</h1>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// const DeleteAssetBtn = ({ imageID, deletedAsset }) => {
//   let assetLoaderDict = useSelector((state) => state.system.assetLoaderDict);
//   const updatedAssetLoaderDict = { ...assetLoaderDict };
//   const dispatch = useDispatch();

//   const onClickDeleteAssetBtn = () => {
//     dispatch(deleteAsset(deletedAsset.id));
//     dispatch(addDeletedAssets(deletedAsset));
//   };

//   return (
//     <>
//       <img
//         src={deleteIcon}
//         alt="deleteIcon"
//         className="absolute hidden h-5 text-red-500 cursor-pointer right-4 group-hover:flex top-2"
//         onClick={onClickDeleteAssetBtn}
//       />
//     </>
//   );
// };

import React from "react";
import { useSelector } from "react-redux";
import { sideBarType } from "../../constants";
import ImageAssets from "./ImageAssets";
import SoundAssets from "./SoundAssets";

const DisplayAssets = () => {
  const currentSideBarType = useSelector((state) => state.system.sideBarType);

  return (
    <>
      {currentSideBarType === sideBarType.images ? <ImageAssets /> : null}
      {currentSideBarType === sideBarType.sounds ? <SoundAssets /> : null}
    </>
  );
};

export default DisplayAssets;
