import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addDeletedAssets, addRedoAssets } from "./systemSlice";

const initialState = {
  imageAssets: [],
};

export const imageAssetsSlice = createSlice({
  name: "imageAssets",
  initialState,
  reducers: {
    updateImageAssets: (state, action) => {
      state.imageAssets = action.payload;
    },
    updateImageAssetData: (state, action) => {
      let updatedImageAssetList = state.imageAssets.map((asset) => {
        if (asset.id === action.payload.id) {
          return {
            ...action.payload,
          };
        } else {
          return asset;
        }
      });
      state.imageAssets = updatedImageAssetList;
    },
    deleteImageAsset: (state, action) => {
      state.imageAssets = state.imageAssets.filter(
        (asset) => asset.id !== action.payload
      );
    },
    addImageAsset: (state, action) => {
      const index = state.imageAssets.findIndex(
        (asset) => asset.id === action.payload.id
      );

      if (index !== -1) {
        // If the object is found, replace it with the new object
        state.imageAssets[index] = action.payload;
      } else {
        // If the object is not found, push the new object into the array
        state.imageAssets.push(action.payload);
      }
      // state.assets_list.push(action.payload);
    },
    addImageAssetatIndex: (state, action) => {
      // console.log(action.payload);
      // console.log("new: " + action.payload.newAsset);
      const index = state.imageAssets.findIndex(
        (asset) => asset.id === action.payload.selectedAsset.id
      );

      if (index !== -1) {
        // If the object is found, replace it with the new object
        state.imageAssets[index] = action.payload.newAsset;
      } else {
        // If the object is not found, push the new object into the array
        state.imageAssets.push(action.payload.newAsset);
      }
      // state.assets_list.push(action.payload);
    },
  },
});

export const addAssetAndUpdateRedo = createAsyncThunk(
  "game/addAssetAndUpdateRedo",
  async (asset, { dispatch, getState }) => {
    // First, dispatch the addAsset action to gameSlice
    const state = getState();
    const currentAssets = [...state.imageAssets.imageAssets];

    const index = currentAssets.findIndex((obj) => obj.id === asset.id);
    if (index !== -1) {
      dispatch(addRedoAssets(currentAssets[index]));
      currentAssets[index] = await asset;
    } else {
      currentAssets.push(asset);
    }
    dispatch(updateImageAssets(currentAssets));
  }
);

export const addAssetAndUpdateUndo = createAsyncThunk(
  "game/addAssetAndUpdateUndo",
  async (asset, { dispatch, getState }) => {
    // First, dispatch the addAsset action to gameSlice
    const state = getState();
    const currentAssets = [...state.imageAssets.imageAssets];

    const index = currentAssets.findIndex((obj) => obj.id === asset.id);
    if (index !== -1) {
      dispatch(addDeletedAssets(currentAssets[index]));
      currentAssets[index] = await asset;
    } else {
      currentAssets.push(asset);
    }
    dispatch(updateImageAssets(currentAssets));
  }
);

export const {
  updateImageAssets,
  updateImageAssetData,
  deleteImageAsset,
  addImageAsset,
  addImageAssetatIndex,
} = imageAssetsSlice.actions;

export default imageAssetsSlice.reducer;
