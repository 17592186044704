import React from "react";
import "./feedBackForm.css";

const FeedBackForm = () => {
  return (
    <div className="framer-div">
      <iframe src="https://tally.so/r/w7WDb9" className="framer" />
    </div>
  );
};

export default FeedBackForm;
