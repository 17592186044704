import { useSelector, useDispatch } from "react-redux";
import DisplayGame from "../gameRender/DisplayGame";
import { useEffect, useState } from "react";
import { updateGameDisplayCode } from "../../store/features/systemSlice";
import { exitFullScreen } from "../../assets";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import posthog from "posthog-js";
import { posthogCapture } from "../../utils/posthogCapture";
import { Fade, Tooltip } from "@mui/material";

const GamePreview = () => {
  const displayMode = useSelector((state) => state.game.displayMode);
  const code = useSelector((state) => state.game.code);
  const dispatch = useDispatch();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const navigate = useNavigate(); // Use the hook here

  useEffect(() => {
    dispatch(updateGameDisplayCode(code));
    const updateSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // const handleKeyDown = (event) => {
    //   if (event.key === "f" || event.key === "F") {
    //     goBack();
    //   }
    // };

    window.addEventListener("resize", updateSize);
    // window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("resize", updateSize);
      // window.removeEventListener("keydown", handleKeyDown); // Clean up the event listener
    };
  }, []);

  const goBack = () => {
    posthogCapture("MINIMIZE_BUTTON_CLICKED");
    navigate(-1);
  };

  return (
    <div>
      <div
        className={`relative w-screen h-screen overflow-hidden ${!displayMode && "flex justify-center items-center"}`}
      >
        {displayMode ? (
          <div className="w-sreccn h-screen">
            <DisplayGame screenSize={screenSize} />
          </div>
        ) : (
          <div className=" h-screen w-[405px]">
            <DisplayGame screenSize={screenSize} />
          </div>
        )}
        <div className="group relative flex justify-end">
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title="Exit Fullscreen"
            placement="top-end"
          >
            <button
              onClick={goBack}
              className="fixed bottom-5 right-8 flex items-center rounded-[8px] py-3 px-4 font-semibold text-white gradient-dark mb-3 transition-all	ease-linear duration-200 hover:scale-110"
            >
              <img src={exitFullScreen} className="h-4 w-4" alt="tabletIcon" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default GamePreview;

const HoverName = () => {
  return (
    <div className="fixed bottom-20 right-0 items-center justify-center hidden duration-500 group-hover:flex">
      <div className="bg-[#181C20] rounded-md py-2 px-5 shadow shadow-gray-800">
        <div>
          <h1 className="mt-1 text-sm text-[#9B9C9E]">Exit Full Screen</h1>
        </div>
      </div>
    </div>
  );
};
