import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import CloseIcon from "../assets/icons/closeChat.svg";
import enterIcon from "../assets/icons/enterIcon.svg";

import {
  assetIcon,
  cropIcon,
  rotateIcon,
  flipHorizontalIcon,
  flipVerticalIcon,
  zoomInIcon,
  zoomOutIcon,
  undoIcon,
  redoIcon,
  outlineIcon,
  fillIcon,
  eraseIcon,
  pixelIcon,
  animeIcon,
  polyIcon,
  flatIcon,
  noirIcon,
} from "../assets";
import axiosInstance from "../utils/axios";
import { Hidden } from "@mui/material";
import { useDispatch } from "react-redux";
import useSaveCanvas from "../hooks/useSaveCanvas";
import { convertToBlob } from "../utils/convertToBlob";
import convertToBlobUrl from "../utils/convertToBlobUrl";
import { editAssetURL } from "../constants";

const artStyles = [
  { name: "Anime", imageURL: animeIcon, id: "anime" },
  { name: "2D Flat", imageURL: flatIcon, id: "2d-flat" },
  { name: "Pixelated", imageURL: pixelIcon, id: "pixelated" },
  { name: "Noir", imageURL: noirIcon, id: "noir" },
  { name: "Poly", imageURL: polyIcon, id: "poly" },
];
let selectedAsset = null;
const EditAssetModel = ({
  isUpload,
  currImage,
  assetArray,
  setShow,
  setIsUpload,
}) => {
  const [undoHistory, setUndoHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const cropperRef = useRef(null);
  const [currImageId, setCurrImageId] = useState(currImage);
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);
  const [isCropEnabled, setIsCropEnabled] = useState(false);
  const [canvasImage, setCanvasImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [imageCategory, setImageCategory] = useState("");
  const [artStyle, setArtStyle] = useState(null);
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [upScallerbuttonClicked, setUpScallerButtonClicked] = useState(false);
  const [showPromptBar, setShowPromptBar] = useState(false);
  const [promptValue, setPromptValue] = useState("");

  useEffect(() => {
    // console.log(isUpload);
    if (isUpload) {
      setCanvasImage(currImage.blobURL);
      setImageCategory(currImage.category);
    } else {
      assetArray.forEach((asset) => {
        if (asset.id === currImageId) {
          selectedAsset = isUpload ? null : asset;
          setCanvasImage(asset.blobURL);
          setImageCategory(asset.category);
        }
      });
    }
    setIsUpload(false);
  }, [currImageId]);

  useEffect(() => {
    if (isDrawingMode && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      const image = new Image();
      image.src = canvasImage;
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        setContext(ctx);
      };
    }
  }, [isDrawingMode, canvasImage]);

  const getScaleFactors = () => {
    const canvas = canvasRef.current;
    const scaleX = canvas.width / canvas.offsetWidth;
    const scaleY = canvas.height / canvas.offsetHeight;
    return { scaleX, scaleY };
  };

  const startDrawing = (e) => {
    if (!context) return;
    const { scaleX, scaleY } = getScaleFactors();
    context.strokeStyle = "#000000";
    context.lineWidth = 100; // Directly set the line width

    setIsErasing(false);
    context.beginPath();
    context.moveTo(
      e.nativeEvent.offsetX * scaleX,
      e.nativeEvent.offsetY * scaleY
    );
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing || !context) return;
    const { scaleX, scaleY } = getScaleFactors();
    context.lineTo(
      e.nativeEvent.offsetX * scaleX,
      e.nativeEvent.offsetY * scaleY
    );
    context.stroke();
  };

  const stopDrawing = () => {
    if (isDrawing) {
      context.closePath();
      setIsDrawing(false);
      setShowPromptBar(true);
    }
  };

  const handleFill = () => {
    setIsDrawingMode(true);
    setIsErasing(false);
    if (context) {
      // context.strokeStyle = "rgba(0,0,0,1)";
      // context.lineWidth = 100; // Directly set the line width
      context.globalCompositeOperation = "source-over";
      // setIsErasing(true);
      // setIsDrawingMode(true);
    }
  };

  const handleErase = () => {
    setIsDrawing(false);
    if (context) {
      context.strokeStyle = "rgba(0,0,0,1)";
      context.lineWidth = 100; // Directly set the line width
      context.globalCompositeOperation = "destination-out";
      setIsErasing(true);
      setIsDrawingMode(true);
    }
  };

  const exitCanvas = () => {
    setIsDrawingMode(false);
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    setShowPromptBar(false);
    setPromptValue("");
  };

  const saveToHistory = () => {
    const cropper = cropperRef.current.cropper;
    const imageData = cropper.getData();
    const history = { canvasImage, imageData };

    setUndoHistory((prevHistory) => {
      const newHistory = [...prevHistory, history];
      return newHistory;
    });

    setRedoHistory([]);
  };

  const handleUndo = () => {
    if (undoHistory.length > 0) {
      const prevState = undoHistory[undoHistory.length - 1];

      setUndoHistory((prevHistory) => prevHistory.slice(0, -1));
      setRedoHistory((prevHistory) => [...prevHistory, prevState]);

      const cropper = cropperRef.current.cropper;
      setCanvasImage(prevState.canvasImage);
      cropper.setData(prevState.imageData);
    }
  };

  const handleRedo = () => {
    if (redoHistory.length > 0) {
      const nextState = redoHistory[redoHistory.length - 1];

      const cropper = cropperRef.current.cropper;
      setCanvasImage(nextState.canvasImage);
      cropper.setData(nextState.imageData);

      setRedoHistory((prevHistory) => prevHistory.slice(0, -1));
      setUndoHistory((prevHistory) => [...prevHistory, nextState]);
    }
  };

  const createEditedAsset = () => {
    if (cropperRef.current) {
      const dataUrl = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL("image/png");
      setCanvasImage(dataUrl);
    }
  };

  const handleCropOkay = () => {
    saveToHistory();
    createEditedAsset();
  };

  const handleZoomIn = () => {
    exitCanvas();
    cropperRef.current.cropper.zoom(0.1);
  };

  const handleZoomOut = () => {
    exitCanvas();
    cropperRef.current.cropper.zoom(-0.1);
  };

  const handleRotateRight = () => {
    exitCanvas();
    saveToHistory();
    cropperRef.current.cropper.rotate(90);
    createEditedAsset();
  };

  const handleFlipHorizontal = () => {
    exitCanvas();
    saveToHistory();
    const scaleX = flipHorizontal ? 1 : -1;
    cropperRef.current.cropper.scaleX(scaleX);
    setFlipHorizontal(!flipHorizontal);
    createEditedAsset();
  };

  const handleFlipVertical = () => {
    exitCanvas();
    saveToHistory();

    const scaleY = flipVertical ? 1 : -1;
    cropperRef.current.cropper.scaleY(scaleY);
    setFlipVertical(!flipVertical);
    createEditedAsset();
  };

  const handleSave = useSaveCanvas(canvasImage, selectedAsset);

  const disableCrop = () => {
    const cropper = cropperRef.current.cropper;
    setIsCropEnabled(false);
    cropper.clear();
    cropper.setDragMode("move");
  };

  const enableCrop = () => {
    exitCanvas();
    const cropper = cropperRef.current.cropper;
    if (isCropEnabled) {
      disableCrop();
      return;
    }

    setIsCropEnabled(true);
    cropper.crop();
    cropper.setDragMode("crop");
    cropper.setCropBoxData({
      left: 15, // Set the left position
      top: 15, // Set the top position
      width: 200, // Set the width
      height: 200, // Set the height
    });
  };

  const handleArtStyle = async (style) => {
    saveToHistory();
    if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      canvas.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("image_file", blob, "canvas_image.png");
        formData.append("image_name", "canvas_image.png");
        formData.append("art_type", style);

        try {
          const response = await axiosInstance.post(
            editAssetURL + "/art_style",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                accept: "application/json",
              },
            }
          );
          // console.log(response.data.data.url);
          const blobURL = await convertToBlobUrl(response.data.data.url);
          setCanvasImage(blobURL);
          setIsLoading(false);
        } catch (error) {
          console.error("Error uploading file:", error);
          setIsLoading(false);
        }
      }, "image/png");
    }
  };

  const handleRemoveBackground = async () => {
    saveToHistory();
    setIsLoading(true);
    if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      canvas.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("image_file", blob, "canvas_image.png");
        formData.append("image_name", "canvas_image.png");

        try {
          const response = await axiosInstance.post(
            editAssetURL + "/bg-remove",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                accept: "application/json",
              },
              responseType: "arraybuffer",
            }
          );

          const arrayBufferView = new Uint8Array(response.data);
          const blob = new Blob([arrayBufferView], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          setCanvasImage(url);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
        setIsLoading(false);
      }, "image/png");
    }
  };

  const handleNextClick = () => {
    assetArray.forEach((element, index) => {
      if (currImageId === element.id) {
        if (index + 1 === assetArray.length) {
          setCurrImageId(assetArray[0].id);
        } else {
          setCurrImageId(assetArray[index + 1].id);
        }
      }
    });
  };

  const handleBackClick = () => {
    assetArray.forEach((element, index) => {
      if (currImageId === element.id) {
        if (index === 0) {
          setCurrImageId(assetArray[assetArray.length - 1].id);
        } else {
          setCurrImageId(assetArray[index - 1].id);
        }
      }
    });
  };

  const handleUpScalerClick = async () => {
    if (!upScallerbuttonClicked) {
      setUpScallerButtonClicked(true);
      setIsLoading(true);
      if (cropperRef.current) {
        const canvas = cropperRef.current.cropper.getCroppedCanvas();
        canvas.toBlob(async (blob) => {
          const formData = new FormData();
          formData.append("image_file", blob, "canvas_image.png");
          formData.append("image_name", "canvas_image.png");

          try {
            const response = await axiosInstance.post(
              editAssetURL + "/upscaler",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  accept: "application/json",
                },
                responseType: "arraybuffer",
              }
            );

            const url = response.data.data.url;
            const blobURL = await convertToBlobUrl(url);
            setCanvasImage(blobURL);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
          setIsLoading(false);
        }, "image/png");
      }
    }
  };

  const handleCloseClick = () => {
    setShow(false);
  };

  const convertCanvasToBlobURL = (canvasRef) => {
    if (canvasRef.current) {
      // Create a temporary canvas for the drawing with a white background
      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");

      // Set the dimensions to match the drawing canvas
      tempCanvas.width = canvasRef.current.width;
      tempCanvas.height = canvasRef.current.height;

      // Fill the temporary canvas with a white background
      tempCtx.fillStyle = "#FFFFFF";
      tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      // Draw the drawing layer onto the temporary canvas
      tempCtx.drawImage(canvasRef.current, 0, 0);

      // Convert the temporary canvas to a data URL and initiate download
      const dataUrl = tempCanvas.toDataURL("image/png");

      // const link = document.createElement("a");
      // link.href = dataUrl;
      // link.download = "drawing_with_white_background.png";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      return dataUrl;
    }
  };

  const base64ToBlob = async (base64) => {
    const response = await fetch(base64);
    const blob = await response.blob();
    return blob;
  };

  const callGenerativeFillAPI = async () => {
    saveToHistory();
    setIsLoading(true);

    let originalAsset = canvasImage;
    // console.log(originalAsset);

    let invertedImage = convertCanvasToBlobURL(canvasRef);
    // console.log(invertedImage);

    const formData = new FormData();

    const originalAssetBlob = await base64ToBlob(originalAsset);
    // console.log(originalAssetBlob);
    const invertedImageBlob = await base64ToBlob(invertedImage);

    formData.append("image_file", originalAssetBlob, "originalAsset.png");
    formData.append("image_name", "originalAsset.png");
    formData.append("mask_image_file", invertedImageBlob, "invertedImage.png");
    formData.append("mask_image_name", "invertedImage.png");
    formData.append("prompt", promptValue);

    try {
      setShowPromptBar(false);
      setPromptValue("");
      const response = await axiosInstance.post(
        editAssetURL + "/generative_art",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
        }
      );

      // console.log(response);
      const url = response.data.data.url;
      const blobURL = await convertToBlobUrl(url);
      setCanvasImage(blobURL);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setIsLoading(false);
  };

  const callOutlineAPI = async () => {
    saveToHistory();
    setIsLoading(true);

    let originalAsset = canvasImage;
    let invertedImage = convertCanvasToBlobURL(canvasRef);
    originalAsset = await convertToBlob(originalAsset);
    invertedImage = await convertToBlob(invertedImage);
    // console.log({ originalAsset });
    // console.log({ invertedImage });

    const formData = new FormData();
    formData.append("image_file", originalAsset, "originalAsset.png");
    formData.append("image_name", "originalAsset.png");

    try {
      const response = await axiosInstance.post(
        editAssetURL + "/outline",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
          },
          responseType: "arraybuffer",
        }
      );

      const arrayBufferView = new Uint8Array(response.data);
      const blob = new Blob([arrayBufferView], { type: "image/png" });
      const url = URL.createObjectURL(blob);
      setCanvasImage(url);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    setIsLoading(false);

    // const link = document.createElement("a");
    // link.href = invertedImage;
    // link.download = "drawing_with_white_background.png";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  return (
    <div className="p-7">
      <div className="flex justify-between">
        <p className="text-[#E8E9E9]">Edit Assets</p>
        <div className="flex">
          {/* <div
            className={`text-[#252527] bg-[#E8E9E9] px-2 py-1 text-xs mr-3 font-bold rounded-sm cursor-pointer ${upScallerbuttonClicked ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleUpScalerClick}
          >
            UpScaler
          </div> */}
          <div
            className="text-[#E8E9E9] bg-[#252527] px-2 py-1 text-xs rounded-sm cursor-pointer"
            onClick={() => {
              handleSave();
              handleCloseClick();
            }}
          >
            Save
          </div>
          <div className="ml-2 cursor-pointer" onClick={handleCloseClick}>
            <img src={CloseIcon} />
          </div>
        </div>
      </div>
      <div className="pt-10 flex">
        <div className="w-1/2 flex justify-around">
          <div className="flex justify-around my-10">
            <div className="flex flex-col justify-around">
              <div
                className="flex flex-col items-center justify-center cursor-pointer hover:scale-110"
                onClick={handleFlipVertical}
              >
                <img
                  src={flipVerticalIcon}
                  width="20px"
                  height="20px"
                  alt="Flip Vertical"
                />
                <p className="text-[#E8E9E9] text-[8px]">Flip Vertical</p>
              </div>
              <div
                className="flex flex-col items-center justify-center cursor-pointer hover:scale-110"
                onClick={handleFlipHorizontal}
              >
                <img
                  src={flipHorizontalIcon}
                  width="20px"
                  height="20px"
                  alt="Flip Horizontal"
                />
                <p className="text-[#E8E9E9] text-[8px]">Flip Horizontal</p>
              </div>
              <div
                className="flex flex-col items-center justify-center cursor-pointer hover:scale-110"
                onClick={handleRotateRight}
              >
                <img src={rotateIcon} width="20px" height="20px" alt="Rotate" />
                <p className="text-[#E8E9E9] text-[8px]">Rotate</p>
              </div>
              <div
                className="flex flex-col items-center justify-center cursor-pointer hover:scale-110"
                onClick={enableCrop}
              >
                <img src={cropIcon} width="20px" height="20px" alt="Crop" />
                <p className="text-[#E8E9E9] text-[8px]">Crop</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-4 flex justify-center">
              <div className="flex justify-around">
                <div
                  className="flex flex-col items-center w-14 justify-center cursor-pointer hover:scale-110"
                  onClick={handleUndo}
                >
                  <img src={undoIcon} width="15px" height="15px" alt="Undo" />
                  <p className="text-[#E8E9E9] text-[8px]">Undo</p>
                </div>
                <div
                  className="flex flex-col items-center w-14 justify-center cursor-pointer hover:scale-110"
                  onClick={handleZoomIn}
                >
                  <img
                    src={zoomInIcon}
                    width="15px"
                    height="15px"
                    alt="Zoom In"
                  />
                  <p className="text-[#E8E9E9] text-[8px]">Zoom In</p>
                </div>
                <div
                  className="flex flex-col items-center w-14 justify-center cursor-pointer hover:scale-110"
                  onClick={handleZoomOut}
                >
                  <img
                    src={zoomOutIcon}
                    width="15px"
                    height="15px"
                    alt="Zoom Out"
                  />
                  <p className="text-[#E8E9E9] text-[8px]">Zoom Out</p>
                </div>
                <div
                  className="flex flex-col items-center w-14 justify-center cursor-pointer hover:scale-110"
                  onClick={handleRedo}
                >
                  <img src={redoIcon} width="15px" height="15px" alt="Redo" />
                  <p className="text-[#E8E9E9] text-[8px]">Redo</p>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="h-[230px] w-[230px] flex justify-center items-center text-white ">
                Loading...
              </div>
            ) : (
              <>
                <div
                  className={`w-[230px] h-[auto] overflow-hidden`}
                  style={{ position: "relative" }}
                >
                  <Cropper
                    ref={cropperRef}
                    src={canvasImage}
                    style={{ height: "auto", width: "230px" }}
                    aspectRatio={1}
                    guides={true}
                    ready={() => disableCrop()}
                  />
                  <div className={`${isDrawingMode ? "block" : "hidden"}`}>
                    <canvas
                      ref={canvasRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "auto",
                        zIndex: 1, // Ensures the canvas is on top
                      }}
                      onMouseDown={startDrawing}
                      onMouseMove={draw}
                      onMouseUp={stopDrawing}
                      onMouseLeave={stopDrawing}
                    />
                  </div>
                </div>

                <div
                // className={`${!isLoading && !isDrawingMode ? "block" : "hidden"}`}
                ></div>
              </>
            )}
            <div className="mt-4 flex justify-center">
              <div className="flex justify-around items-center">
                {!isCropEnabled && !showPromptBar ? (
                  <>
                    <div
                      className="w-14 cursor-pointer text-center hover:scale-110"
                      onClick={handleBackClick}
                    >
                      <p className="text-[#E8E9E9] text-[10px] underline">
                        Back
                      </p>
                    </div>
                    <div className="w-fit text-center">
                      <p className="text-[#E8E9E9] font-bold text-[14px]">
                        {imageCategory}
                      </p>
                    </div>
                    <div
                      className="w-14 cursor-pointer text-center hover:scale-110"
                      onClick={handleNextClick}
                    >
                      <p className="text-[#E8E9E9] text-[10px] underline">
                        Next
                      </p>
                    </div>
                  </>
                ) : null}

                {isCropEnabled ? (
                  <>
                    <div
                      className="flex items-center justify-center bg-[#353535] text-white px-5 py-1 text-[10px] rounded tracking-widest cursor-pointer"
                      onClick={disableCrop}
                    >
                      Cancel
                    </div>
                    <div
                      className="flex items-center justify-center bg-white px-5 py-1 ml-3 text-[10px] rounded tracking-widest cursor-pointer"
                      onClick={handleCropOkay}
                    >
                      Okay
                    </div>
                  </>
                ) : null}
                {showPromptBar ? (
                  <div className="w-full flex items-center justify-center">
                    <div className="relative w-full">
                      <input
                        type="text"
                        placeholder="Enter text"
                        className="px-4 py-2 pr-10 text-white rounded-md focus:border-purple-500 focus:outline-none border border-gray-300 transition-colors duration-300 ease-in-out w-full"
                        value={promptValue}
                        onChange={(e) => setPromptValue(e.target.value)}
                        style={{
                          backgroundColor:
                            "rgb(30 32 38 / var(--tw-bg-opacity))",
                        }}
                      />
                      <button
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-white rounded-md hover:bg-purple-500"
                        onClick={callGenerativeFillAPI}
                      >
                        <img src={enterIcon} alt="enter" className="w-4" />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 px-6 ml-4">
          <div className="bg-[#2B2B2B] h-full rounded-md p-4">
            <div>
              <p className="text-[#E8E9E9] text-sm">Change Art Style</p>
              <div className="flex items-center py-3">
                {artStyles.map((style) => (
                  <div
                    className={`flex flex-col items-center justify-center pr-4 cursor-pointer `}
                    key={style.id}
                    onClick={() => {
                      setArtStyle(style.id);
                    }}
                  >
                    <img
                      className={`h-10 w-10 rounded-[50%] ${artStyle === style.id && "border-[#2E40DE] border-[3px]"}`}
                      src={style.imageURL}
                    ></img>
                    <p className="text-[#E8E9E9] text-[8px] pt-1">
                      {style.name}
                    </p>
                  </div>
                ))}
              </div>
              <div
                className="flex items-center justify-center w-fit bg-[#000000] text-white px-5 py-1 mb-2 text-[10px] rounded tracking-widest cursor-pointer hover:scale-105"
                onClick={() => {
                  if (artStyle) {
                    setIsLoading(true);
                    handleArtStyle(artStyle);
                  }
                }}
              >
                Apply
              </div>
            </div>
            <div>
              <p className="text-[#E8E9E9] text-sm">Generative Brush</p>
              <div className="flex items-center py-3">
                <div
                  className="flex items-center justify-center bg-[#000000] text-white px-5 py-1 text-[10px] rounded tracking-widest cursor-pointer"
                  onClick={handleFill}
                >
                  <img
                    className="mr-2"
                    src={fillIcon}
                    width="10px"
                    height="10px"
                    alt="Fill"
                  />
                  Fill
                </div>
                {/* <div
                  className="h-8 w-8 ml-4 flex justify-center items-center rounded-[50%] bg-[#000000] cursor-pointer"
                  onClick={handleFill}
                >
                  <img src={fillIcon} width="15px" height="15px" alt="Erase" />
                </div> */}
                <div
                  className="h-8 w-8 ml-4 flex justify-center items-center rounded-[50%] bg-[#D9D9D9] cursor-pointer"
                  onClick={handleErase}
                >
                  <img src={eraseIcon} width="15px" height="15px" alt="Erase" />
                </div>
              </div>
            </div>
            <div>
              <p className="text-[#E8E9E9] text-sm">Background Removal</p>
              <div className="flex items-center py-3">
                {/* <div className="flex items-center justify-center bg-white px-5 py-1 text-[10px] rounded tracking-widest cursor-pointer">
                  <img
                    className="mr-2"
                    src={outlineIcon}
                    width="10px"
                    height="10px"
                    alt="Outline"
                  />
                  Outline
                </div> */}
                <div
                  onClick={() => handleRemoveBackground()}
                  className="flex items-center justify-center bg-white px-5 py-1 text-[10px] rounded tracking-widest cursor-pointer"
                >
                  Remove
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAssetModel;
