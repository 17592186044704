import React from "react";
import { rotateScreenIcon } from "../assets";

const RotateDevicePrompt = () => {
  return (
    <div
      id="rotate-device-overlay"
      className="fixed inset-0 z-50 items-center justify-center hidden bg-black bg-opacity-80"
    >
      <img
        src={rotateScreenIcon}
        alt="rotatescreen"
        className="animated-element"
      />
    </div>
  );
};

export default RotateDevicePrompt;
