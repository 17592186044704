import {
  addAsset,
  addAssetatIndex,
  deleteAsset,
} from "../../store/features/gameSlice";
import {
  addDeletedAssets,
  updateAssetIterationTypes,
  updateDisplayAssetOptions,
  updateGameAssetIterationAPIError,
  updateIsAssetLoading,
  updatePromptValue,
  updateSelectedAsset,
  updateSelectedAssetIterationType,
} from "../../store/features/systemSlice";
import {
  assetIterationTypeValues,
  assetIterationURL,
  fileUploadURL,
  sideBarType,
} from "../../constants";
import convertToBlobUrl from "../../utils/convertToBlobUrl";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import axiosInstance from "../../utils/axios";
import NewPromptBar from "../../components/NewPromptBar";
import { useEffect } from "react";
import { posthogCapture } from "../../utils/posthogCapture";
import {
  addImageAsset,
  addImageAssetatIndex,
  updateImageAssetData,
} from "../../store/features/imageAssetsSlice";
import {
  addSoundAsset,
  updateSoundAssetData,
} from "../../store/features/soundAssetsSlice";
import UploadAssets from "./UploadAssets";

const ChatBar = ({ setIsViewChartOpen }) => {
  const currentSideBarType = useSelector((state) => state.system.sideBarType);

  const imageAssets = useSelector((state) => state.imageAssets.imageAssets);
  const promptValue = useSelector((state) => state.system.promptValue);
  const selectedAssetIterationType = useSelector(
    (state) => state.system.selectedAssetIterationType
  );
  const assetIterationTypes = useSelector(
    (state) => state.system.assetIterationTypes
  );
  const selectedAsset = useSelector((state) => state.system.selectedAsset);
  const displayAssetOptions = useSelector(
    (state) => state.system.displayAssetOptions
  );
  // const [displayAssetOptions, setDisplayAssetOptions] = useState(false);
  const [onHoverAssetIterateType, setOnHoverAssetIterateType] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const game_id = useSelector((state) => state.game.gameID);

  const apiError = useSelector(
    (state) => state.system.gameAssetIterationAPIError
  );
  const dispatch = useDispatch();

  const onClickPromptBtn = async () => {
    if (uploadedImage && promptValue) {
      setUploadedImage(null);
      dispatch(updatePromptValue(""));
      const asset = await fileUpload(selectedAsset);
      iterateAsset(asset, promptValue);
    } else if (uploadedImage) {
      // console.log("uploadedImage executed");
      fileUpload(selectedAsset);
      setUploadedImage(null);
    } else {
      iterateAsset(selectedAsset, promptValue);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedImage({
        file,
        name: "user_upload_" + uuidv4().substring(0, 4) + ".png",
        url: URL.createObjectURL(file),
      });
    }
  };

  const fileUpload = async (selectedAsset) => {
    const formData = new FormData();
    let asset = null;

    // console.log(uploadedImage);
    formData.append("asset_image", uploadedImage.file);
    formData.append("asset_name", uploadedImage.name);

    try {
      dispatch(updateGameAssetIterationAPIError(""));
      dispatch(updateIsAssetLoading(true));
      // dispatch(addAsset({ id: "temp_asset" }));

      const response = await axiosInstance.post(fileUploadURL, formData);
      const { asset_url, asset_id } = response.data.data;
      // console.log("Upload Successful", response.data);
      const blobURL = await convertToBlobUrl(asset_url);
      asset = {
        id: asset_id,
        name: uploadedImage.name,
        url: asset_url,
        blobURL,
        prompt: "user-upload",
        ai_prompt: "user-upload",
        category: "uploaded_image",
        // isSelected: false,
      };

      if (selectedAsset) {
        let newAsset = {
          ...asset,
          name: selectedAsset.name,
          category: selectedAsset.category,
        };
        dispatch(updateSelectedAsset(newAsset));
        dispatch(addImageAssetatIndex({ selectedAsset, newAsset }));
      } else {
        dispatch(addImageAsset(asset));
        dispatch(updateSelectedAsset(asset));
      }
    } catch (error) {
      dispatch(
        updateGameAssetIterationAPIError(
          `An error occurred while uploading the asset. Please try again.`
        )
      );
      console.error("Upload Failed", error);
    }
    // dispatch(deleteAsset("temp_asset"));
    dispatch(updateIsAssetLoading(false));
    return asset;
  };

  const iterateAsset = async (selectedAsset, promptValue) => {
    const replaceAsset = async () => {
      let prompt = promptValue;
      const old_prompt = selectedAsset
        ? selectedAsset.prompt.toLowerCase()
        : "";
      if (
        old_prompt.includes("background") ||
        old_prompt.includes("non-distracting")
      ) {
        prompt = promptValue + " non-distracting";
      }
      const promptValueFirstWord = promptValue.split(" ")[0].toLowerCase();
      if (!selectedAsset) {
        dispatch(
          updateGameAssetIterationAPIError("Please select an asset to continue")
        );
        return;
      }

      let iterationType = selectedAssetIterationType
        ? selectedAssetIterationType
        : promptValueFirstWord === "change"
          ? "change"
          : "modify";
      if (selectedAsset.name.includes("user_upload")) {
        iterationType = "modify";
      }
      const payload = {
        iteration_type: iterationType,
        asset_url: selectedAsset.url,
        prompt: prompt,
        asset_id: selectedAsset.id,
        game_id: game_id,
        old_prompt:
          iterationType === "modify"
            ? selectedAsset.prompt
            : selectedAsset.ai_prompt,
      };
      // console.log(old_prompt);
      try {
        dispatch(updateGameAssetIterationAPIError(""));
        dispatch(updatePromptValue(""));
        dispatch(addDeletedAssets(selectedAsset));
        dispatch(updateIsAssetLoading(true));

        const apiType =
          currentSideBarType === sideBarType.sounds ? "sound" : "image";

        // console.log({ apiType });
        const response = await axiosInstance.post(
          assetIterationURL + "/" + apiType,
          payload
        );
        const blobURL = await convertToBlobUrl(response.data.data.ai_resp.url);
        // console.log(response.data.data.ai_resp.url);
        let newAsset = {
          ...selectedAsset,
          url: response.data.data.ai_resp.url,
          blobURL,
          prompt:
            iterationType === "modify"
              ? selectedAsset.prompt + " " + prompt
              : prompt,
          initial_prompt: selectedAsset.initial_prompt,
          // iterationType === "change" ? prompt : selectedAsset.initial_prompt,
        };
        // console.log({ newAsset });
        dispatch(updateSelectedAssetIterationType(null));

        if (currentSideBarType === sideBarType.sounds) {
          dispatch(updateSoundAssetData(newAsset));
        } else if (currentSideBarType === sideBarType.images) {
          dispatch(updateImageAssetData(newAsset));
        }

        dispatch(updateIsAssetLoading(false));
        dispatch(updateSelectedAsset(""));
      } catch (error) {
        dispatch(
          updateGameAssetIterationAPIError(
            `An error occurred while generating the asset. Please try again.`
          )
        );
        console.error("Error during API call:", error);
        dispatch(updateIsAssetLoading(false));
      }
    };

    const generateNewAsset = async () => {
      const assetID = uuidv4();

      const payload = {
        iteration_type: "create",
        asset_url: "create",
        prompt: promptValue,
        asset_id: assetID,
        game_id: game_id,
        old_prompt: "",
      };
      try {
        dispatch(updateGameAssetIterationAPIError(""));
        dispatch(updatePromptValue(""));

        if (currentSideBarType === sideBarType.sounds) {
          dispatch(addSoundAsset({ id: assetID }));
        } else {
          dispatch(addImageAsset({ id: assetID }));
        }

        const apiType =
          currentSideBarType === sideBarType.sounds ? "sound" : "image";

        // console.log({ apiType });

        const response = await axiosInstance.post(
          assetIterationURL + "/" + apiType,
          payload
        );
        const blobURL = await convertToBlobUrl(response.data.data.ai_resp.url);
        let newAsset = {
          id: assetID,
          name:
            selectedAsset.asset_type === "image"
              ? "new_image_" + uuidv4().substring(0, 4) + ".png"
              : "new_sound_" + uuidv4().substring(0, 4) + ".mp3",
          url: response.data.data.ai_resp.url,
          blobURL,
          prompt: promptValue,
          ai_prompt: promptValue,
          category: "new_asset",
          // isSelected: false,
        };
        dispatch(updateSelectedAssetIterationType(null));
        if (currentSideBarType === sideBarType.images) {
          dispatch(addImageAsset(newAsset));
        } else if (currentSideBarType === sideBarType.sounds) {
          dispatch(addSoundAsset(newAsset));
        }
      } catch (error) {
        dispatch(
          updateGameAssetIterationAPIError(
            `An error occurred while generating the asset. Please try again.`
          )
        );
        console.error("Error during API call:", error);
      }
    };

    // const promptValueFirstWord = promptValue.split(" ")[0].toLowerCase();
    if (selectedAssetIterationType === "create") {
      generateNewAsset();
    } else {
      replaceAsset();
    }
  };

  useEffect(() => {
    if (promptValue === "/") {
      posthogCapture("ASSETS_CHANGE_PROMPT");
      dispatch(updateDisplayAssetOptions(true));
    } else if (promptValue[0] === "/" && assetIterationTypes.length !== 0) {
      dispatch(updateDisplayAssetOptions(true));
    } else {
      dispatch(updateDisplayAssetOptions(false));
    }
  }, [promptValue]);

  const onClickAssetIterationType = (iterationType) => {
    dispatch(updateDisplayAssetOptions(false));
    dispatch(updateSelectedAssetIterationType(iterationType));
    dispatch(updatePromptValue(""));
    dispatch(updateAssetIterationTypes(assetIterationTypeValues));
  };

  const onHandleMouseEnter = (iterationType) => {
    const assetTypes = assetIterationTypeValues.map((each) => {
      if (each.iterationType === iterationType) {
        return { ...each, isActive: true };
      } else {
        return { ...each, isActive: false };
      }
    });
    dispatch(updateAssetIterationTypes(assetTypes));
  };

  useEffect(() => {
    if (promptValue[0] === "/" && promptValue !== "") {
      let assetTypes = assetIterationTypeValues.filter((each) =>
        each.displayName.includes(promptValue)
      );

      if (assetTypes.length > 0) {
        // Create a new array with the first element's isActive set to true
        // and keep the rest of the elements as they are.
        assetTypes = assetTypes.map((each, index) => ({
          ...each,
          isActive: index === 0 ? true : each.isActive,
        }));
      }
      dispatch(updateAssetIterationTypes(assetTypes));
    }
  }, [promptValue]);

  return (
    <div className="px-6 w-full">
      <div className="relative">
        {displayAssetOptions && assetIterationTypes.length > 0 ? (
          <div className="absolute bottom-[76px] w-[100%] bg-[#0D0F10] px-4 py-3 rounded-xl">
            {assetIterationTypes.map((each, index) => (
              <div
                className={`px-6 py-2 mb-2 rounded-xl ${each.isActive ? "bg-[#131619]" : null}`}
                key={index}
                onMouseEnter={() => onHandleMouseEnter(each.iterationType)}
                onClick={() => onClickAssetIterationType(each.iterationType)}
              >
                <h1 className="text-white">{each.displayName}</h1>
                <p className="text-[#545454]">{each.description}</p>
              </div>
            ))}
          </div>
        ) : null}

        <NewPromptBar
          onClickPromptBtn={onClickPromptBtn}
          allowImgUpload
          handleImageChange={handleImageChange}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          onClickAssetIterationType={onClickAssetIterationType}
          placeholder="Whisper '/' to summon the ancient commands"
        />
        {apiError && <p className="mx-auto mt-2 text-red-500">{apiError}</p>}
      </div>
      {/* <UploadAssets /> */}
    </div>
    // <div className="absolute bottom-0 bg-[#191A1D] w-[95%] rounded-t-2xl border-t border-solid border-white border-opacity-[0.2] pt-[32px] pb-[29px] px-[51px] mt-[136px]">
    //   <div className="flex justify-end mb-[28px]">
    //     <button
    //       onClick={() => setIsViewChartOpen(true)}
    //       className="flex items-center mt-[28px]rounded-[8px] py-[14px] px-4 font-semibold text-white bg-transparent outline-none"
    //     >
    //       <img src={chatIcon} className="mr-3" alt="chatIcon" />
    //       View Chart
    //     </button>
    //   </div>

    //   {/* <PromptBar
    //     onClickPromptBtn={onClickPromptBtn}
    //     allowImgUpload
    //     handleImageChange={handleImageChange}
    //     uploadedImage={uploadedImage}
    //     setUploadedImage={setUploadedImage}
    //   /> */}
    //   {apiError && <p className="mx-auto mt-5 text-red-500">{apiError}</p>}
    // </div>
  );
};

export default ChatBar;
