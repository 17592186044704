import React from "react";
import "./PopUp.css";

const PopUp = ({ show, setShow, children, onClose }) => {
  return (
    <div
      className="absolute flex justify-center items-center top-0 left-0 w-screen h-screen backdrop-blur-sm"
      onClick={() => {
        if (!onClose) {
          setShow(false);
        }
      }}
    >
      <div
        className="popup_main_contianer w-1/2 h-fit bg-[#131619]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PopUp;
