import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyUndoAndRedo,
  removeDeletedAsset,
  removeRedoAssets,
  updateGameDisplayCode,
  updateSelectedAsset,
  updateSideBarType,
} from "../../store/features/systemSlice";
import { assetsIcon, codeIcon, redoIcon, undoIcon } from "../../assets";
import CodeEditor from "./CodeEditor";
import {
  addAssetAndUpdateRedo,
  addAssetAndUpdateUndo,
} from "../../store/features/imageAssetsSlice";
import posthog from "posthog-js";
import { posthogCapture } from "../../utils/posthogCapture";
import { sideBarType } from "../../constants";
import PlayAudio from "../../assets/icons/PlayAudio.svg";
import PauseAudio from "../../assets/icons/PauseAudio.svg";
import DisplayAssets from "./DisplayAssets";

const Sidebar = ({ assets_list }) => {
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const currentSideBarType = useSelector((state) => state.system.sideBarType);

  const onClickApplyCode = () => {
    dispatch(updateGameDisplayCode(editorRef.current.getValue()));
  };
  let cursorPosition = null;
  useEffect(() => {
    const handleSaveShortcut = (event) => {
      if (event.key === "s" && event.ctrlKey) {
        event.preventDefault(); // Prevent the browser's default Save action
        cursorPosition = editorRef.current.getPosition();
        dispatch(updateGameDisplayCode(editorRef.current.getValue()));
        handleSetCursorPosition();
      }
    };

    document.addEventListener("keydown", handleSaveShortcut);

    return () => {
      document.removeEventListener("keydown", handleSaveShortcut);
    };
  }, []);

  const handleSetCursorPosition = async () => {
    setTimeout(function () {
      editorRef.current.setPosition(cursorPosition);
      editorRef.current.focus();
    }, 500);
  };

  return (
    <>
      <div className="flex flex-col justify-between min-w-[460px] w-[35%] bg-[#0D0F10] rounded-[20px] px-5 py-5 h-full overflow-y-scroll">
        <div className="flex bg-[#15161A] py-3 px-3 rounded-[12px] mb-3">
          <button
            className={`flex items-center justify-center py-[12px] px-[12px] rounded-[8px] text-[#E8E9E9] mr-[6px] temp ${
              currentSideBarType === sideBarType.images
                ? "btn-tertiary flex-1"
                : "btn-quaternary"
            } `}
            style={
              currentSideBarType === sideBarType.images
                ? { width: "45%" }
                : { width: "40%" }
            }
            onClick={() => {
              posthogCapture("ASSETS_TAB_BUTTON_CLICKED");
              dispatch(updateSideBarType(sideBarType.images));
            }}
          >
            <img src={assetsIcon} className="mr-[12px]" alt="assetsIcon" />
            Assets
          </button>
          <button
            className={`flex items-center justify-center py-[12px] px-[12px] rounded-[8px] text-[#E8E9E9] mr-[6px] temp ${
              currentSideBarType === sideBarType.sounds
                ? "btn-tertiary flex-1"
                : "btn-quaternary"
            } `}
            style={
              currentSideBarType === "CONSOLE"
                ? { width: "45%" }
                : { width: "40%" }
            }
            onClick={() => {
              posthogCapture("ASSETS_TAB_BUTTON_CLICKED");
              dispatch(updateSideBarType(sideBarType.sounds));
            }}
          >
            <img src={assetsIcon} className="mr-[12px]" alt="assetsIcon" />
            Audio
          </button>
          <button
            className={`flex items- justify-center py-[12px] px-[16px] rounded-[8px] text-[#E8E9E9] ml-[6px] ${
              currentSideBarType === sideBarType.console
                ? "btn-tertiary"
                : "btn-quaternary"
            } `}
            onClick={() => {
              posthogCapture("CONSOLE_TAB_BUTTON_CLICKED");
              dispatch(updateSideBarType(sideBarType.console));
            }}
          >
            <img
              src={codeIcon}
              className={`${currentSideBarType === "CONSOLE"}`}
              alt="codeIcon"
            />
            {/* {currentSideBarType === "CONSOLE" && "Console"} */}
          </button>
        </div>
        {currentSideBarType === "CONSOLE" ? (
          <>
            <div className="py-[20px] pl-0 pr-[10px] bg-[#1B1C1C] rounded-[12px] h-[75%] mb-3">
              <CodeEditor editorRef={editorRef} />
            </div>
            <button
              onClick={onClickApplyCode}
              className="w-full rounded-[8px] btn-tertiary py-3 px-4 font-semibold  text-white"
            >
              Apply
            </button>
          </>
        ) : (
          <>
            <div className="flex flex-col h-[100%] mb-3 overflow-y-scroll">
              <div className="flex items-center justify-between mb-4">
                <p className="text-[#9B9C9E] font-semibold text-[20px] pl-4">
                  Selected Assets
                </p>
                <div className="flex gap-4">
                  <UndoAssetBtn />
                  <RedoAssetBtn />
                </div>
              </div>
              <DisplayAssets />
              {/* {currentSideBarType === "ASSETS" ? (
                <DisplayAssets assets_list={assets_list} />
              ) : null}

              {currentSideBarType === "AUDIO" ? (
                <>
                  <AudioAsset
                    source={
                      "https://aicade-ui-assets.s3.amazonaws.com/GameAssets/sfx/collect_2.mp3"
                    }
                    name={"Gunshot Audio"}
                  />
                </>
              ) : null} */}

              {/* <button
                onClick={() => {
                  dispatch(emptyUndoAndRedo());
                }}
                className="w-full rounded-[8px] btn-tertiary py-3 px-4 font-semibold  text-white"
              >
                Apply
              </button> */}
            </div>
          </>
        )}
      </div>
    </>
    // <div className="min-w-[460px] w-[31.5%] h-[100%] bg-[#0D0F10] rounded-[20px] p-[21px]">
    //   <div className="flex bg-[#15161A] py-[12px] px-[16px] rounded-[12px] mb-[12px]">
    //     <button
    //       className={`flex items-center py-[12px] px-[16px] rounded-[8px] text-[#E8E9E9] mr-[6px] ${
    //         !isConsoleOpen
    //           ? "btn-secondary w-[58.5%]"
    //           : "btn-quaternary w-[41.5%]"
    //       } `}
    //       onClick={() => setIsConsoleOpen(false)}
    //     >
    //       <img src={assetsIcon} className="mr-[12px]" alt="assetsIcon" />
    //       Assets
    //     </button>
    //     <button
    //       className={`flex items-center py-[12px] px-[16px] rounded-[8px] text-[#E8E9E9] ml-[6px] ${
    //         isConsoleOpen
    //           ? "btn-secondary w-[58.5%]"
    //           : "btn-quaternary w-[41.5%]"
    //       } `}
    //       onClick={() => setIsConsoleOpen(true)}
    //     >
    //       <img src={codeIcon} className="mr-[12px]" alt="codeIcon" />
    //       Console
    //     </button>
    //   </div>
    //   {isConsoleOpen ? (
    //     <>
    //       <div className="py-[20px] pl-0 pr-[10px] bg-[#1B1C1C] rounded-[12px] ">
    //         <CodeEditor editorRef={editorRef} />
    //       </div>
    //       <button
    //         onClick={onClickApplyCode}
    //         className="w-full rounded-[8px] btn-tertiary py-[14px] px-4 font-semibold mt-12 text-white"
    //       >
    //         Apply
    //       </button>
    //     </>
    //   ) : (
    //     <>
    //       <div className="flex flex-col justify-between h-[100%] mt-10">
    //         <div className="min-h-[100vh]">
    //           <div className="flex items-center justify-between">
    //             <p className="text-[#9B9C9E] my-4 font-semibold text-[22px] ml-4">
    //               Select Assets
    //             </p>
    //             <div className="flex gap-4">
    //               <UndoAssetBtn />
    //               <RedoAssetBtn />
    //             </div>
    //           </div>
    //           <NewDisplayAssets assets_list={assets_list} />
    //         </div>
    //         <div>
    //           <button
    //             onClick={() => {
    //               dispatch(emptyUndoAndRedo());
    //             }}
    //             className="w-full rounded-[8px] btn-tertiary py-[14px] px-4 font-semibold mt-12 text-white"
    //           >
    //             Apply
    //           </button>
    //         </div>
    //       </div>
    //     </>
    //   )}
    // </div>
  );
};

export default Sidebar;

const UndoAssetBtn = () => {
  const deletedAssets = useSelector((state) => state.system.deletedAssets);
  const dispatch = useDispatch();

  const onClickUndoDeletedAsset = async () => {
    const lastAsset = deletedAssets[deletedAssets.length - 1];
    dispatch(addAssetAndUpdateRedo(lastAsset));
    dispatch(removeDeletedAsset());
    dispatch(updateSelectedAsset(""));
  };

  return (
    <>
      {deletedAssets.length > 0 ? (
        <button
          onClick={onClickUndoDeletedAsset}
          className="h-10 px-4 flex items-center rounded-[8px] text-[#E8E9E9] btn-quaternary"
        >
          <img src={undoIcon} className="mr-[12px]" alt="codeIcon" />
          Undo
        </button>
      ) : (
        <div></div>
      )}
    </>
  );
};

const RedoAssetBtn = () => {
  const redoAssets = useSelector((state) => state.system.redoAssets);
  const dispatch = useDispatch();

  const onClickRedoAsset = async () => {
    dispatch(addAssetAndUpdateUndo(redoAssets[redoAssets.length - 1]));
    dispatch(removeRedoAssets());
    dispatch(updateSelectedAsset(""));
  };

  return (
    <>
      {redoAssets.length > 0 ? (
        <button
          onClick={onClickRedoAsset}
          className="h-10 px-4 flex items-center rounded-[8px] text-[#E8E9E9] btn-quaternary"
        >
          <img src={redoIcon} className="mr-[12px]" alt="codeIcon" />
          Redo
        </button>
      ) : null}
    </>
  );
};
