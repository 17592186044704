import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import * as braincadeSDK from "../../utils/braincadeSDK";
import Phaser from "phaser";
import {
  displayScreenConstants,
  phaserUrl,
  sdkUrl,
  vfxUrl,
} from "../../constants";
import StartScreen from "../gameScreen/StartScreen";
import MainMenuScreen from "../gameScreen/MainMenuScreen";
import GameOverScreen from "../gameScreen/GameOverScreen";
import SplashScreen from "../gameScreen/SplashScreen";
import { updateDisplayMode } from "../../store/features/gameSlice";
import { updateDisplayScreen } from "../../store/features/gameDisplaySlice";
import Rating from "../rating/Rating";

const DisplayGame = ({ screenSize }) => {
  const gameDisplayCode = useSelector((state) => state.system.gameDisplayCode);
  const displayScreen = useSelector((state) => state.gameDisplay.displayScreen);
  const backgroundSplash = useSelector((state) => state.game.background_splash);
  const initialRender = useRef(true);
  const [mute, setMute] = useState(false);
  const [fullyRendered, setFullyRendered] = useState(false);

  const { width } = screenSize;
  const [gameConfig, setGameConfig] = useState(null);
  const [gameDetails, setGameDetails] = useState({});
  const isLoading = useSelector((state) => state.system.isLoading);
  const [rating, setRating] = useState(false);
  const [game, setGame] = useState(null);
  const gameRef = useRef();
  gameRef.current = game;

  const dispatch = useDispatch();

  if (!Boolean(sessionStorage.getItem("hasGivenRating"))) {
    sessionStorage.setItem("hasGivenRating", "false");
  }

  let hasGivenRating = sessionStorage.getItem("hasGivenRating") === "true";

  useEffect(() => {
    if (!isLoading) {
      dispatch(updateDisplayScreen(displayScreenConstants.splash));
      setTimeout(() => {
        dispatch(updateDisplayScreen(displayScreenConstants.start));
      }, 2000);
    }
  }, [isLoading]);

  useEffect(() => {
    window.addEventListener("pauseGame", handlePauseEvent);
    window.addEventListener("gameOver", handleGameOverEvent);

    const fetchScript = async () => {
      try {
        const phaserScript = await fetch(phaserUrl);
        const sdkScript = await fetch(sdkUrl);
        const vfxScript = await fetch(vfxUrl);
        const scriptText =
          (await phaserScript.text()) +
          "\n\n" +
          (await sdkScript.text()).replaceAll("export", "") +
          "\n\n" +
          (await vfxScript.text())
            .replaceAll("export default VFXLibrary;", "")
            .replaceAll("export", "");
        return scriptText;
      } catch (error) {
        return "error";
      }
    };

    if (gameDisplayCode.length > 5000) {
      fetchScript().then((response) => {
        const functionCode =
          response +
          `\n\n
          ${gameDisplayCode}\n\n
          return config`;

        const config = new Function(functionCode)();
        config.dataObject.buttonName = "start";
        config.dataObject.imageUrl = backgroundSplash;
        config.parent = "game-container";
        // console.log(config);
        setGameConfig(config);
      });
    }
  }, [gameDisplayCode, backgroundSplash]);

  useEffect(() => {
    if (initialRender.current === false) {
      handleDestroyGame();
    }
  }, [gameDisplayCode]);

  useEffect(() => {
    if (gameConfig) {
      dispatch(updateDisplayMode(gameConfig.orientation));
    }
  }, [gameConfig]);

  useEffect(() => {
    if (displayScreen === displayScreenConstants.splash) {
      dispatch(updateDisplayScreen(displayScreenConstants.splash));
      setTimeout(() => {
        dispatch(updateDisplayScreen(displayScreenConstants.start));
        initialRender.current = false;
      }, 3000);
    }

    if (displayScreen === displayScreenConstants.game) {
      if (!game && fullyRendered) {
        setGame(new Phaser.Game(gameConfig));
      }
    }
    if (displayScreen === displayScreenConstants.gameOver) {
      setRating(true);
    }
  }, [displayScreen]);

  const handlePauseEvent = () => {
    dispatch(updateDisplayScreen(displayScreenConstants.mainMenu));
  };

  const handleResumeGame = () => {
    dispatch(updateDisplayScreen(displayScreenConstants.game));
    braincadeSDK.initiateResumeGame();
  };

  const handleRestartGame = () => {
    dispatch(updateDisplayScreen(displayScreenConstants.game));
  };

  const handleGameOverEvent = (e) => {
    dispatch(updateDisplayScreen(displayScreenConstants.gameOver));
    setGameDetails(e.detail);
  };

  const handleDestroyGame = () => {
    dispatch(updateDisplayScreen(displayScreenConstants.start));
    setGame(null);
    braincadeSDK.initiateDestroyGame();
  };

  const handleStartGame = () => {
    braincadeSDK.initiateDestroyGame();
    setGame(null);
    dispatch(updateDisplayScreen(displayScreenConstants.game));
  };

  const handleMuteButton = () => {
    braincadeSDK.initiateToggleGameSounds();
  };

  const handleOnClickMenu = () => {
    braincadeSDK.initiateDestroyGame();
    dispatch(updateDisplayScreen(displayScreenConstants.start));
    braincadeSDK.initiateDestroyGame();
  };

  const handleRatingOnClose = () => {
    sessionStorage.setItem("hasGivenRating", "true");
    setRating(false);
  };

  useEffect(() => {
    setFullyRendered(true);
  }, []);

  return !gameConfig ? (
    <></>
  ) : (
    <div className="h-full relative">
      {displayScreen === displayScreenConstants.splash && <SplashScreen />}
      {displayScreen === displayScreenConstants.start && (
        <StartScreen
          dataObject={gameConfig.dataObject}
          handleOnClickStart={handleStartGame}
          screenSize={screenSize}
        />
      )}
      {displayScreen === displayScreenConstants.mainMenu && (
        <MainMenuScreen
          dataObject={gameConfig.dataObject}
          width={width}
          setMute={setMute}
          mute={mute}
          handleOnClickReStart={handleStartGame}
          handleOnClickResume={handleResumeGame}
          handleOnClickMenu={handleOnClickMenu}
          handleMuteButton={handleMuteButton}
        />
      )}
      {displayScreen === displayScreenConstants.gameOver && (
        <GameOverScreen
          dataObject={gameConfig.dataObject}
          width={width}
          gameDetails={gameDetails}
          handleOnClickReStart={handleStartGame}
          handleOnClickMenu={handleOnClickMenu}
        />
      )}
      {rating && !hasGivenRating && <Rating onClose={handleRatingOnClose} />}
      <div
        id="game-container"
        style={{
          display:
            displayScreen === displayScreenConstants.game ||
            displayScreen === displayScreenConstants.mainMenu ||
            displayScreen === displayScreenConstants.gameOver
              ? "block"
              : "none",
        }}
      ></div>
    </div>
  );
};

export default DisplayGame;
