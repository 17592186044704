import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  updateGameAssetIterationAPIError,
  updateIsAssetLoading,
  updateSelectedAsset,
} from "../store/features/systemSlice";
import {
  addImageAsset,
  addImageAssetatIndex,
} from "../store/features/imageAssetsSlice";
import { fileUploadURL } from "../constants";
import axiosInstance from "../utils/axios";
import convertToBlobUrl from "../utils/convertToBlobUrl";

const useSaveCanvas = (editedAsset, selectedAsset) => {
  const dispatch = useDispatch();
  // console.log({ editedAsset });
  // console.log({ selectedAsset });

  const handleSave = useCallback(async () => {
    // Convert the temporary canvas to a blob
    let assetBlob;
    if (editedAsset instanceof Blob) {
      assetBlob = editedAsset;
    } else if (editedAsset instanceof HTMLCanvasElement) {
      assetBlob = await new Promise((resolve) =>
        editedAsset.toBlob(resolve, "image/png")
      );
    } else if (typeof editedAsset === "string") {
      const response = await fetch(editedAsset);
      assetBlob = await response.blob();
    } else {
      assetBlob = new Blob([editedAsset], { type: "image/png" });
    }

    const formData = new FormData();
    let asset = null;

    // Append the canvas image blob to the form data
    formData.append("asset_image", assetBlob, "drawing.png");
    formData.append("asset_name", "drawing.png");

    // Download the edited image
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(assetBlob);
    // link.download = "edited_image.png";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    try {
      dispatch(updateGameAssetIterationAPIError(""));
      dispatch(updateIsAssetLoading(true));

      const response = await axiosInstance.post(fileUploadURL, formData);
      const { asset_url, asset_id } = response.data.data;
      const blobURL = await convertToBlobUrl(asset_url);

      if (selectedAsset) {
        asset = {
          id: asset_id,
          name: selectedAsset.name,
          url: asset_url,
          blobURL,
          prompt: selectedAsset.prompt,
          ai_prompt: selectedAsset.ai_prompt,
          category: selectedAsset.category,
        };
        let newAsset = {
          ...asset,
          name: selectedAsset.name,
          category: selectedAsset.category,
        };
        dispatch(updateSelectedAsset(newAsset));
        dispatch(addImageAssetatIndex({ selectedAsset, newAsset }));
      } else {
        asset = {
          id: asset_id,
          name: "uploaded_asset",
          url: asset_url,
          blobURL,
          prompt: "user-upload",
          ai_prompt: "user-upload",
          category: "uploaded_image",
        };
        dispatch(addImageAsset(asset));
        dispatch(updateSelectedAsset(asset));
      }
    } catch (error) {
      dispatch(
        updateGameAssetIterationAPIError(
          `An error occurred while uploading the asset. Please try again.`
        )
      );
      console.error("Upload Failed", error);
    }
    dispatch(updateIsAssetLoading(false));
    dispatch(updateSelectedAsset(null));
  }, [editedAsset, dispatch, selectedAsset]);

  return handleSave;
};

export default useSaveCanvas;
