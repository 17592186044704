import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  soundAssets: [],
};

export const soundAssetsSlice = createSlice({
  name: "soundAssets",
  initialState,
  reducers: {
    updateSoundAssets: (state, action) => {
      state.soundAssets = action.payload;
    },
    updateSoundAssetData: (state, action) => {
      let updatedSoundAssetList = state.soundAssets.map((asset) => {
        if (asset.id === action.payload.id) {
          return {
            ...action.payload,
          };
        } else {
          return asset;
        }
      });
      state.soundAssets = updatedSoundAssetList;
    },
    addSoundAsset: (state, action) => {
      const index = state.soundAssets.findIndex(
        (asset) => asset.id === action.payload.id
      );

      if (index !== -1) {
        // If the object is found, replace it with the new object
        state.soundAssets[index] = action.payload;
      } else {
        // If the object is not found, push the new object into the array
        state.soundAssets.push(action.payload);
      }
      // state.assets_list.push(action.payload);
    },
  },
});

export const { updateSoundAssets, updateSoundAssetData, addSoundAsset } =
  soundAssetsSlice.actions;

export default soundAssetsSlice.reducer;
