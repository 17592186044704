import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalCredits: 0,
  creditTrasactionHistory: [],
};

export const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {
    addCredits: (state, action) => {
      state.totalCredits = state.totalCredits + action.payload;
    },
    reduceCredits: (state, action) => {
      state.totalCredits = state.totalCredits - action.payload;
    },
    updateCreditTrasactionHistory: (state, action) => {
      state.creditTrasactionHistory = [
        ...state.creditTrasactionHistory,
        action.payload,
      ];
    },
  },
});

export const { addCredits, reduceCredits, updateCreditTrasactionHistory } =
  creditSlice.actions;

export default creditSlice.reducer;
