import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  avatarURL: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateName: (state, action) => {
      state.name = action.payload;
    },
    updateAvatarURL: (state, action) => {
      state.avatarURL = action.payload;
    },
  },
});

export const { updateName, updateAvatarURL } = userSlice.actions;

export default userSlice.reducer;
