import { useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect } from "react";
import { updateCode } from "../../store/features/gameSlice";

import Editor from "@monaco-editor/react";
import { removeAssetLoader } from "../../utils/removeAssetLoader";
import { addAssetLoader } from "../../utils/addAssetLoader";
// import convertToAssetDict from "../../utils/convertToAssetDict";
// import removeAssetsList from "../../utils/removeAssetsList";
// import { updateGameDisplayCode } from "../../store/features/systemSlice";

const CodeEditor = ({ editorRef }) => {
  let code = useSelector((state) => state.game.code);
  // const assetLoaderDict = useSelector((state) => state.system.assetLoaderDict);
  // if (assetLoaderDict.length !== 0) {
  //   code = removeAssetLoader(code);
  //   code = addAssetLoader(code, assetLoaderDict);
  // }

  // let assets_list = useSelector((state) => state.game.assets_list);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   createFinalCode();
  // }, [assets_list]);

  // const createFinalCode = async () => {
  //   let blobAssetDict = await convertToAssetDict(assets_list);
  //   const strigifiedAssets = JSON.stringify(blobAssetDict);
  //   const assetDict = `const assets_list = ${strigifiedAssets} \n\n`;
  //   const assetRemovedCode = removeAssetsList(code);
  //   const updatedCode = assetDict + assetRemovedCode.trimStart();
  //   dispatch(updateCode(updatedCode));
  //   dispatch(updateGameDisplayCode(updatedCode));
  // };

  const files = {
    "script.js": {
      name: "script.js",
      language: "javascript",
      value: code,
    },
  };

  const file = files["script.js"];

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  const handleOnchange = () => {
    dispatch(updateCode(editorRef.current.getValue()));
  };

  const editorOptions = {
    minimap: { enabled: false },
    hideCursorInOverviewRuler: true,
    hideLineNumbers: true,
    hideGlyphMargin: true,
    hideStatusbar: true,
    hideActionBar: true,
    wordWrap: "on",
    fontSize: 13,
    automaticLayout: true,
    cursorBlinking: "solid",
    cursorStyle: "line",
    cursorWidth: 2,
    scrollbar: {
      vertical: "visible",
      verticalScrollbarSize: 6,
      arrowSize: 30,
    },
  };

  return (
    <>
      <div className="overflow-hidden bg-[#1B1C1C]">
        <Editor
          height="55vh"
          width="100%"
          theme="vs-dark"
          onMount={handleEditorDidMount}
          path={file.name}
          defaultLanguage={file.language}
          value={code}
          options={editorOptions}
          onChange={handleOnchange}
        />
      </div>
    </>
  );
};

export default memo(CodeEditor);
