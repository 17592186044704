// const baseURL = "http://13.48.242.206";
// const baseURL = "http://40.121.158.189:8000";
// const baseBackendURL = "https://api.braincade.in";
// https://api.braincade.in/backend/game/description

export const gameIdeaGenerationUrl = "/backend/game/description";
export const gameGenerationUrl = "/backend/game/generate";
export const assetIterationURL = "/backend/iterate";
// export const soundIterationURL = "/backend/iterate/sound";
export const fileUploadURL = "/backend/upload/image";
export const saveGameCodeURL = "/backend/game/code_file";
export const gameSaveURL = "/backend/game/code";
export const editAssetURL = "/backend/editAsset";

export const globalFunctionsURL =
  "https://aicade-ui-assets.s3.amazonaws.com/Phaser-code/Global-code/global_functions.js";

export const phaser3URL =
  "https://cdn.jsdelivr.net/npm/phaser@v3.80.1/dist/phaser.js";
export const phaser2URL =
  "https://cdn.jsdelivr.net/npm/phaser-ce@2.19.1/build/phaser.js";

export const phaserUrl =
  "https://cdnjs.cloudflare.com/ajax/libs/phaser/3.80.1/phaser.min.js";
export const sdkUrl =
  "https://raw.githubusercontent.com/ks-braincade/braincadeSDK/main/src/braincadeSDK.js";
export const vfxUrl =
  "https://raw.githubusercontent.com/ks-braincade/braincadeSDK/main/src/vfxLibrary.js";

export const trendingPrompts = [
  "You are Aladdin on a mission to save your princess while collecting powerups and coins",
  "Forrest Gump starts running through different iconic movie scenes, jumping over obstacles and avoiding pitfalls.",
  "Assume the role of James Bond in a static 2D environment, tactically moving to escape a fortified enemy compound.",
  "Engage in space battles, shooting enemy ships while evading return fire in a Star Wars themed shooter.",
  "A slicing frenzy game where you cut non-degradable waste but not degradable waste.",
  "Stack and balance puzzle boxes, creating lines to clear space and score points.",
  "From Rags to Riches test your reflex to tap on the gold before it disappears.",
  "Train your Superman to fly, navigating between Kryptonian mountains to survive.",
  "Launch cannonballs from a catapult to break colonial structures, aiming for maximum destruction.",
  "Survive on an unknown planet, by avoiding and dodging unknown alien viruses coming from all directions.",
  "Whack the viruses before they can get to your system in a race against time.",
  "Prince of Persia jumps on platforms, to test his comfort with heights.",
  "Fly a dragon, tapping and holding to navigate through medieval landscapes, avoiding obstacles.",
  "Defend your castle by shooting arrows at approaching enemies from all directions freely, aiming carefully to protect your kingdom.",
  "Make a game where Demons are falling from the sky and you have to shoot them before they hit you.",
  "Make a game where Barbie has to cross the road without getting hit by cars.",
  "You are a ninja shooting rotating knives around you trying to survive from an army of enemy ninjas surrounding you.",
  "Compete in a darts game, aiming and throwing at targets with increasing difficulty.",
  "Engage in hunting and fishing challenges, shooting and catching various animals and fish.",
  "Make a game where a monkey is swinging to collect fruits.",
  "An Olympic game where you are skiing down mountains.",
  "A game where you are driving a car on mountains trying to keep your balance.",
  "Create a fighting game where Elon Musk fights Jeff Bezos in an epic 1v1.",
  "Design a racing game where you maneuver through bustling traffic, skillfully dodging cars as you accelerate down a busy highway.",
  "A game where I use my reflexes to build walls to escape bombs while I rise up.",
];

export const gameGenerationStatuses = [
  {
    name: "in_queue",
    displayText: "Dreaming Up Worlds...",
    isCompleted: true,
    isActive: true,
    index: 0,
  },
  {
    name: "code_generating",
    displayText: "Crafting the Canvas...",
    isCompleted: false,
    isActive: false,
    index: 1,
  },
  {
    name: "asset_prompting",
    displayText: "Bringing Pixels to Life...",
    isCompleted: false,
    isActive: false,
    index: 2,
  },
  {
    name: "asset_generating",
    displayText: "Weaving the Gameplay...",
    isCompleted: false,
    isActive: false,
    index: 3,
  },
  {
    name: "done",
    displayText: "Sprinkling the Final Sparkle...",
    isCompleted: false,
    isActive: false,
    index: 4,
  },
];

export const assetIterationTypeValues = [
  {
    iterationType: "modify",
    displayName: "/modify",
    description: "Modify elements in the selected asset",
    isActive: true,
  },
  {
    iterationType: "change",
    displayName: "/change",
    description: "Replace the seleceted asset with your new asset",
    isActive: false,
  },
  {
    iterationType: "create",
    displayName: "/create",
    description: "Create a new asset",
    isActive: false,
  },
];

export const displayScreenConstants = {
  gameOver: "GAME_OVER",
  mainMenu: "MAIN_MENU",
  start: "START",
  splash: "SPLASH",
  game: "GAME",
};

export const sideBarType = {
  console: "CONSOLE",
  images: "IMAGES",
  sounds: "SOUNDS",
};

export const regexToRemoveSoundsLoader =
  /let\s+soundsLoader\s*=\s*\{[^}]*\};?/g;
export const regexToRemoveAssetsLoader =
  /let\s+assetsLoader\s*=\s*\{[^}]*\};?/g;

export const initalGameSlice = {
  displayMode: true,
  prompt: "",
  gameDescription: "",
  gameID: "",
  gameTitle: "",
  promptID: "",
  code: "",
  background_splash: "/default_background.jpg",
};
