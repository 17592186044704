import { useState } from "react";

const Image = ({ src, classNames }) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <div
        className={`${classNames} bg-gray-500 animate-pulse`}
        style={{ display: !loading ? "none" : "block" }}
      ></div>
      <img
        style={{ display: loading ? "none" : "block" }}
        onLoad={() => setLoading(false)}
        src={src}
        className={`${classNames}`}
        alt="assets"
      />
    </>
  );
};

export default Image;
