import { createSlice } from "@reduxjs/toolkit";
import { displayScreenConstants } from "../../constants";

const initialState = {
  displayScreen: displayScreenConstants.splash,
};

export const gameDisplaySlice = createSlice({
  name: "gameDisplay",
  initialState,
  reducers: {
    updateDisplayScreen: (state, action) => {
      state.displayScreen = action.payload;
    },
  },
});

export const { updateDisplayScreen } = gameDisplaySlice.actions;

export default gameDisplaySlice.reducer;
