import React from "react";
import "./GameScreen.css";
import cornerIcon from "../../assets/image/pause_menu_corner.svg";
import * as braincadeSDK from "../../utils/braincadeSDK";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayScreen } from "../../store/features/gameDisplaySlice";
import { displayScreenConstants } from "../../constants";

const cornerIcons = (
  <>
    <div className="absolute top-2 left-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
    <div className="absolute top-2 right-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
    <div className="absolute bottom-2 left-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
    <div className="absolute bottom-2 right-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
  </>
);

const GameOverScreen = ({
  dataObject,
  width,
  gameDetails,
  handleOnClickReStart,
  handleOnClickMenu,
}) => {
  const dispatch = useDispatch();

  const handleRestartGame = () => {
    dispatch(updateDisplayScreen(displayScreenConstants.game));
    braincadeSDK.initiateRestartGame();
  };
  const background_splash = useSelector(
    (state) => state.game.background_splash
  );

  const mainMenuButtons = (
    <div className="mainMenuButtons absolute -bottom-8 w-full flex flex-col items-center">
      <div
        onClick={handleOnClickReStart}
        className="startButton mt-2 bg-no-repeat bg-center bg-contain w-28 h-12 flex justify-center items-center cursor-pointer"
      >
        <p
          className="text-xl text-white font-medium"
          style={{ paddingBottom: "8%" }}
        >
          RETRY
        </p>
      </div>
      <div
        onClick={handleOnClickMenu}
        className="startButton mt-2 bg-no-repeat bg-center bg-contain w-28 h-12 flex justify-center items-center cursor-pointer"
      >
        <p
          className="text-xl text-white font-medium"
          style={{ paddingBottom: "8%" }}
        >
          MENU
        </p>
      </div>
    </div>
  );

  return (
    <div
      className="absolute text-center flex justify-center items-center h-full w-full backdrop-blur-sm"
      style={{
        // backgroundImage: `url(${background_splash})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        textShadow: "-1px 2px 0 rgba(0, 0, 0, 0.518)",
      }}
    >
      <div
        className="flex flex-col justify-around items-center w-full"
        style={{
          margin: "3%",
          fontFamily: "pixel_digivolve",
          height: width < 622 ? "55%" : "70%",
        }}
      >
        <div className="mainTextTitle text-white text-4xl font-medium mb-4">
          {dataObject.name}
        </div>
        <div
          className="min-h-[200px] flex flex-col justify-start items-center relative"
          style={{
            background:
              "linear-gradient(97.91deg, rgba(254, 130, 219, 0.5) 6.1%, rgba(104, 228, 255, 0.5) 103.66%), linear-gradient(0deg, rgba(98, 93, 223, 0.22), rgba(98, 93, 223, 0.22))",
            borderRadius: "5%",
            width: `${width < 622 ? "90%" : "300px"}`,
          }}
        >
          {cornerIcons}
          <div className="mainTextTitle gameOverTitle text-white text-3xl font-medium mb-2">
            GAME OVER
          </div>
          <div className="scoreTimeContainer mt-2">
            {Object.keys(gameDetails).map((each, index) =>
              each === "time" ? (
                <p key={index} className="text-white text-sm">
                  {each}:{" "}
                  {gameDetails[each]
                    ? `${(gameDetails[each] / 10000000).toFixed(2)} sec`
                    : "0"}
                </p>
              ) : (
                <p key={index} className="text-white text-lg">
                  {each}: {gameDetails[each] ? `${gameDetails[each]}` : "0"}
                </p>
              )
            )}
          </div>
          <div className="h-24 w-1/2"></div>
          {mainMenuButtons}
        </div>
      </div>
    </div>
  );
};

export default GameOverScreen;
