import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import Home from "./views/home/Home";
import Chat from "./views/chat/Chat";
import GameRender from "./views/gameRender/GameRender";
import GamePreview from "./views/gamePreview/GamePreview";
import ShareGame from "./views/shareGame.jsx/ShareGame";
import EditGame from "./views/editGame/EditGame";
import FullScreenEditor from "./views/fullScreenEditor/FullScreenEditor";
import Login from "./views/auth/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  updatePhaserURL,
  updatePhaserVersion,
} from "./store/features/systemSlice";
import Loader from "./components/Loader";
import ProtectedRoute from "./utils/ProtectedRoute";
import { useEffect } from "react";
import posthog from "posthog-js";
import Cookies from "js-cookie";
import FeedBackForm from "./views/feedBackForm/FeedBackForm";
import PageNotFound from "./views/errorPages/PageNotFound";

// Sentry.init({
//   dsn: "https://4e9084fc2dc75124947713ca0de86b9c@o4506347187994624.ingest.sentry.io/4506347189633024",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const App = () => {
  const isLoading = useSelector((state) => state.system.isLoading);
  const dispatch = useDispatch();
  const url = window.location.href;
  if (url.includes("pv2")) {
    dispatch(updatePhaserVersion("pv2"));
    dispatch(updatePhaserURL());
  }

  useEffect(() => {
    const emailId = Cookies.get("userEmail");
    posthog.identify(emailId);
  }, []);

  return (
    <>
      {/* Main Routes */}

      <BrowserRouter basename="/">
        {isLoading && <Loader />}
        <Routes>
          <Route path="/" element={<ProtectedRoute children={<Home />} />} />
          <Route
            path="/chat"
            element={<ProtectedRoute children={<Chat />} />}
          />
          <Route
            path="/render-game"
            element={<ProtectedRoute children={<GameRender />} />}
          />
          <Route
            path="/preview"
            element={<ProtectedRoute children={<GamePreview />} />}
          />
          <Route path="/play/:id" element={<ShareGame />} />
          <Route
            path="/edit/:id"
            element={<ProtectedRoute children={<EditGame />} />}
          />
          <Route
            path="/global-editor"
            element={<ProtectedRoute children={<FullScreenEditor />} />}
          />
          <Route
            path="/feedback"
            element={<ProtectedRoute children={<FeedBackForm />} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>

      
    </>
  );
};

export default App;
