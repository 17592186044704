import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAssetIterationTypes,
  updateDisplayAssetOptions,
  updateGameAssetIterationAPIError,
  updatePromptValue,
  updateSelectedAssetIterationType,
} from "../store/features/systemSlice";
import {
  crossIcon,
  imageUploadIcon,
  promptArrowIcon,
  magicWandIcon,
} from "../assets";
import { posthogCapture } from "../utils/posthogCapture";
import { assetIterationTypeValues } from "../constants";
import { useEffect } from "react";

const NewPromptBar = ({
  onClickPromptBtn,
  allowImgUpload,
  handleImageChange,
  uploadedImage,
  setUploadedImage,
  placeholder,
  onClickAssetIterationType,
}) => {
  const assetIterationTypes = useSelector(
    (state) => state.system.assetIterationTypes
  );
  const promptValue = useSelector((state) => state.system.promptValue);
  const displayAssetOptions = useSelector(
    (state) => state.system.displayAssetOptions
  );
  const selectedAssetIterationType = useSelector(
    (state) => state.system.selectedAssetIterationType
  );
  const [focused, setFocused] = useState(false);
  const dispatch = useDispatch();

  const onClickPromptSubmitBtn = () => {
    posthogCapture("GENERATE_IDEA_BUTTON_CLICKED", {
      prompt: promptValue,
    });
    onClickPromptBtn();
  };

  const handleOnKeyDown = (e) => {
    dispatch(updateGameAssetIterationAPIError(""));

    if (e.key === "Space") {
      e.preventDefault();
    }

    if (promptValue[0] === "/" && e.key === "Enter") {
      const iterationType = assetIterationTypes.filter(
        (each) => each.isActive === true
      );
      if (iterationType.length > 0) {
        onClickAssetIterationType(iterationType[0]?.iterationType);
      } else {
        dispatch(updateDisplayAssetOptions(false));
        dispatch(updatePromptValue(""));
        dispatch(updateAssetIterationTypes(assetIterationTypeValues));
        dispatch(updateGameAssetIterationAPIError("Command Not Found"));
        dispatch(updateSelectedAssetIterationType(null));
      }
    } else if (promptValue[0] !== "/" && e.key === "Enter") {
      onClickPromptBtn();
    }
    if (promptValue === "" && e.key === "Backspace") {
      dispatch(updateSelectedAssetIterationType(null));
    }

    if (displayAssetOptions && e.key === "ArrowUp") {
      let currentIndex;
      assetIterationTypes.filter((each, index) => {
        if (each.isActive === true) {
          currentIndex = index;
        }
        return each.isActive === true;
      });
      if (currentIndex > 0) {
        currentIndex = currentIndex - 1;
      } else {
        currentIndex = 0;
      }
      const assetTypes = assetIterationTypes.map((each, index) => {
        if (index === currentIndex) {
          return { ...each, isActive: true };
        } else {
          return { ...each, isActive: false };
        }
      });
      dispatch(updateAssetIterationTypes(assetTypes));
    }
    if (displayAssetOptions && e.key === "ArrowDown") {
      let currentIndex;
      assetIterationTypes.filter((each, index) => {
        if (each.isActive === true) {
          currentIndex = index;
        }
        return each.isActive === true;
      });
      if (currentIndex < assetIterationTypes.length - 1) {
        currentIndex = currentIndex + 1;
      } else {
        currentIndex = assetIterationTypes.length - 1;
      }
      const assetTypes = assetIterationTypes.map((each, index) => {
        if (index === currentIndex) {
          return { ...each, isActive: true };
        } else {
          return { ...each, isActive: false };
        }
      });
      dispatch(updateAssetIterationTypes(assetTypes));
    }
  };

  useEffect(() => {
    let timeout;

    const checkInput = () => {
      if (promptValue !== "" && !placeholder) {
        posthogCapture("MANUAL_PROMPT_ENTRY");
      }
    };

    timeout = setTimeout(checkInput, 4000);

    return () => clearTimeout(timeout);
  }, [promptValue]);

  return (
    <div className="relative ">
      {uploadedImage && (
        <div className="absolute bottom-20 uploaded-image">
          <div className="relative group -10 flex items-center">
            <button className="absolute right-1 top-1 -translate-y-1/2 translate-x-1/2 rounded-full border border-white bg-gray-500 p-0.5 text-white transition-colors hover:bg-black z-50 hover:opacity-100 group-hover:opacity-100 md:opacity-0">
              <img
                src={crossIcon}
                alt="Remove"
                className="max-w-[80px]"
                onClick={() => {
                  setUploadedImage("");
                }}
              />
            </button>
            <div className="mr-4">
              <img
                src={uploadedImage.url}
                alt="Preview"
                className="w-20 h-20 rounded-[23px] relative"
              />
            </div>
            <div className="text-gray-400 mt-12 text-sm">
              Note: Our asset upload is in beta—expect some quirks. If it
              doesn't work as intended, please retry.
            </div>
          </div>
        </div>
      )}
      <div
        className={`relative h-16 flex shadow-custom w-full px-4 bg-[#25252] m-auto border rounded-xl duration-500 ${
          focused
            ? "shadow-custom border-[#2420E3]"
            : "border border-[#545454] shadow-none"
        }`}
      >
        <div className="flex items-center justify-center w-full">
          {selectedAssetIterationType ? (
            <button className="px-2 rounded-md text-white bg-black py-1">
              /{selectedAssetIterationType}
            </button>
          ) : null}
          <input
            className="w-full text-lg bg-transparent outline-none placeholder:text-[#545454] font-medium text-white p-6"
            placeholder={
              placeholder
                ? placeholder
                : "Write the narrative of your game in the editor. "
            }
            value={promptValue}
            onChange={(event) =>
              dispatch(updatePromptValue(event.target.value))
            }
            onFocus={(e) => setFocused(true)}
            onBlur={(e) => setFocused(false)}
            onKeyDown={(e) => handleOnKeyDown(e)}
          />
          {allowImgUpload && (
            <ImageUploadBtn handleImageChange={handleImageChange} />
          )}
          {/* {onClickMagicWandBtn ? <MagicWandBtn onClickMagicWandBtn={onClickMagicWandBtn}/> : null} */}
          <div
            onClick={onClickPromptSubmitBtn}
            className={`w-10 h-10 prompt-btn rounded-[12px] flex items-center justify-center cursor-pointer duration-500 ${
              !uploadedImage && promptValue?.length === 0
                ? "opacity-50"
                : "opacity-100"
            }`}
          >
            <img src={promptArrowIcon} alt="promptArrowIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPromptBar;

const ImageUploadBtn = ({ handleImageChange }) => {
  return (
    <>
      <label htmlFor="uploadImage">
        <img
          src={imageUploadIcon}
          className="mr-6 cursor-pointer"
          alt="upload icon"
          onClick={() => posthogCapture("UPLOAD_IMAGE_BUTTON_CLICKED")}
        />
      </label>
      <input
        id="uploadImage"
        type="file"
        style={{ display: "none" }} // hide the file input
        onChange={handleImageChange} // handle file selection
      />
    </>
  );
};

const MagicWandBtn = ({ onClickMagicWandBtn }) => {
  return (
    <img
      src={magicWandIcon}
      alt="magicWandIcon"
      className="mr-4 cursor-pointer"
      onClick={() => onClickMagicWandBtn()}
    />
  );
};
