import React from "react";
import "./GameScreen.css";
import cornerIcon from "../../assets/image/pause_menu_corner.svg";
import { useDispatch } from "react-redux";
import * as braincadeSDK from "../../utils/braincadeSDK";
import { updateDisplayScreen } from "../../store/features/gameDisplaySlice";
import { displayScreenConstants } from "../../constants";
import musicOn from "../../assets/image/music_on.svg";
import muteOn from "../../assets/image/mute_on.svg";
import redSlash from "../../assets/image/red_slash.svg";
import { useState } from "react";

const cornerIcons = (
  <>
    <div className="absolute top-2 left-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
    <div className="absolute top-2 right-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
    <div className="absolute bottom-2 left-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
    <div className="absolute bottom-2 right-2">
      <img src={cornerIcon} width="25px"></img>
    </div>
  </>
);

const MainMenuScreen = ({
  dataObject,
  width,
  setMute,
  mute,
  handleOnClickResume,
  handleOnClickReStart,
  handleOnClickMenu,
  handleMuteButton,
}) => {
  const [music, setMusic] = useState(false);

  const handleMuteButtonClicked = () => {
    setMute(!mute);
    handleMuteButton();
  };

  const mainMenuButtons = (
    <div className="mainMenuButtons absolute -bottom-8 w-full flex flex-col items-center">
      <div
        onClick={handleOnClickResume}
        className="startButton mt-2 bg-no-repeat bg-center bg-contain w-[40%] h-[50px] flex justify-center items-center cursor-pointer"
      >
        <p
          className="text-xl text-white font-medium"
          style={{ paddingBottom: "8%" }}
        >
          RESUME
        </p>
      </div>
      <div
        onClick={handleOnClickReStart}
        className="startButton mt-1 bg-no-repeat bg-center bg-contain w-[40%] h-[50px] flex justify-center items-center cursor-pointer"
      >
        <p
          className="text-xl text-white font-medium"
          style={{ paddingBottom: "8%" }}
        >
          RESTART
        </p>
      </div>
      <div
        onClick={handleOnClickMenu}
        className="startButton mt-1 bg-no-repeat bg-center bg-contain w-[40%] h-[50px] flex justify-center items-center cursor-pointer"
      >
        <p
          className="text-xl text-white font-medium"
          style={{ paddingBottom: "8%" }}
        >
          MENU
        </p>
      </div>
    </div>
  );

  return (
    <div
      className="absolute text-center flex justify-center items-center h-full w-full backdrop-blur-sm"
      style={{
        textShadow: "-1px 2px 0 rgba(0, 0, 0, 0.518)",
      }}
    >
      <div
        className="flex flex-col justify-around items-center w-full"
        style={{
          margin: "3%",
          fontFamily: "pixel_digivolve",
          // height: width < 622 ? "55%" : "70%",
        }}
      >
        <div className="mainTextTitle text-white text-4xl font-medium mb-4">
          {dataObject.name}
        </div>
        <div
          className="min-h-[200px] h-fit flex flex-col justify-start items-center relative p-2"
          style={{
            background:
              "linear-gradient(97.91deg, rgba(254, 130, 219, 0.5) 6.1%, rgba(104, 228, 255, 0.5) 103.66%), linear-gradient(0deg, rgba(98, 93, 223, 0.22), rgba(98, 93, 223, 0.22))",
            borderRadius: "5%",
            width: `${width < 622 ? "90%" : "300px"}`,
          }}
        >
          {cornerIcons}
          <div className="mainTextTitle text-white text-center text-[28px] font-medium mb-2 ml-2">
            PAUSED
          </div>
          <div className="flex items-center">
            <div
              className="relative cursor-pointer"
              onClick={handleMuteButtonClicked}
            >
              <img src={muteOn} width="40px"></img>
              {mute && (
                <img
                  className="absolute top-[15%] right-[28%] h-8"
                  src={redSlash}
                  height="50px"
                ></img>
              )}
            </div>
            {/* <div
              className="relative flex ml-[20%] cursor-pointer w-8 h-8 mr-[8%]"
              onClick={() => {
                setMusic(!music);
              }}
            >
              <img src={musicOn}></img>
              {music && (
                <img
                  className="absolute top-0 right-0"
                  src={redSlash}
                  width="30px"
                ></img>
              )}
            </div> */}
          </div>
          <div className="w-1/2 h-[150px]"></div>
          {mainMenuButtons}
        </div>
      </div>
    </div>
  );
};

export default MainMenuScreen;
