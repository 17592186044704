import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/icons/closeChat.svg";
import uploadAssetIcon from "../../assets/icons/uploadAssetIcon.svg";
import enterIcon from "../../assets/icons/enterIcon.svg";
import convertToBlobUrl from "../../utils/convertToBlobUrl";

const UploadAssets = ({
  setShow,
  setShowEditImage,
  setIsUpload,
  setCurrImage,
}) => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (file) {
      const blobURL = URL.createObjectURL(file);
      // console.log(blobURL);
      setCurrImage({ ...newAsset, blobURL });
      setIsUpload(true);
      setShowEditImage(true);
      setShow(false);
    }
  }, [file]);

  const newAsset = {
    id: "",
    name: "uploaded_asset",
    category: "new_asset",
    prompt: "",
    ai_prompt: "",
    blobURL: "",
    url: "",
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const submitURL = async () => {
    const blobURL = await convertToBlobUrl(url);
    // console.log(blobURL);
    setCurrImage({ ...newAsset, blobURL });
    setIsUpload(true);
    setShowEditImage(true);
    setShow(false);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  // const handleUpload = async () => {
  //   setUploading(true);
  //   if (file) {
  //     console.log("Uploading file:", file);
  //   } else if (url) {
  //     console.log("Uploading from URL:", url);
  //   } else {
  //     setUploading(false);
  //   }
  // };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleCloseClick = () => {
    setShow(false);
  };

  return (
    <div
      style={{ zIndex: "999" }}
      className="fixed inset-0 flex items-center justify-center h-screen bg-black bg-opacity-80"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div
        style={{
          //   marginRight: "30%",
          backgroundColor: "rgb(30 32 38 / var(--tw-bg-opacity))",
        }}
        className="w-2/5 h-fit p-6 rounded-2xl shadow-lg bg-opacity-100"
      >
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-lg font-semibold text-white">Upload Asset</h1>
          <img
            src={closeIcon}
            alt="cross"
            className="cursor-pointer"
            onClick={handleCloseClick}
          />
        </div>
        <div
          className={`flex flex-col items-center justify-center w-full h-fit py-8 border-2 border-dashed rounded-lg relative ${
            dragActive ? "border-purple-500" : "border-gray-300"
          }`}
        >
          <input
            type="file"
            className="absolute w-full h-full opacity-0 cursor-pointer"
            onChange={handleFileChange}
          />
          <div className="w-4/5 text-center pointer-events-none mb-4">
            <img
              src={uploadAssetIcon}
              alt="upload"
              className="w-14 mx-auto mb-2"
            />
            <p className="text-white text-lg">
              {file ? file.name : "Drop Your File Here Or Browse"}
            </p>
            <p className="text-gray-400 text-xs">
              Maximum Upload File Size: 10 MB
            </p>
          </div>
          <div className="w-4/5 flex flex-col items-center">
            <p className="text-gray-400 text-base mb-4">Or</p>
            <div className="w-4/5 flex items-center justify-center mb-4">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Add URL"
                  className="px-4 py-2 pr-10 text-white rounded-md focus:border-purple-500 focus:outline-none border border-gray-300 transition-colors duration-300 ease-in-out w-full"
                  value={url}
                  onChange={handleUrlChange}
                  style={{
                    backgroundColor: "rgb(30 32 38 / var(--tw-bg-opacity))",
                  }}
                />
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-white rounded-md hover:bg-purple-500"
                  onClick={submitURL}
                  disabled={uploading}
                >
                  <img src={enterIcon} alt="enter" className="w-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAssets;
