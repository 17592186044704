import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addDeletedAssets, addRedoAssets } from "./systemSlice";
import { initalGameSlice } from "../../constants";

const initialState = {
  displayMode: true,
  prompt: "",
  gameDescription: "",
  gameID: "",
  gameTitle: "",
  promptID: "",
  code: "",
  background_splash: "/default_background.jpg",
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    resetGameState: (state, action) => {
      state.prompt = "";
      state.displayMode = true;
      state.gameDescription = "";
      state.gameID = "";
      state.gameTitle = "";
      state.promptID = "";
      state.code = "";
      state.background_splash = "/default_background.jpg";
    },
    updateGameTitle: (state, action) => {
      state.gameTitle = action.payload;
    },
    updateDisplayMode: (state, action) => {
      state.displayMode = action.payload;
    },
    updatePrompt: (state, action) => {
      state.prompt = action.payload;
    },
    updateGameDescription: (state, action) => {
      state.gameDescription = action.payload;
    },
    updateCode: (state, action) => {
      state.code = action.payload;
    },
    updateBackgroundSplash: (state, action) => {
      state.background_splash = action.payload;
    },
    updateGameID: (state, action) => {
      state.gameID = action.payload;
    },
    updatePromptID: (state, action) => {
      state.promptID = action.payload;
    },
    updateAssets_list: (state, action) => {
      // console.log("updateAssets_list executed");
      state.assets_list = action.payload;
    },
    updateAssetData: (state, action) => {
      let updatedAssetList = state.assets_list.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload,
          };
        } else {
          return item;
        }
      });
      state.assets_list = updatedAssetList;
    },
    deleteAsset: (state, action) => {
      state.assets_list = state.assets_list.filter(
        (item) => item.id !== action.payload
      );
    },
    addAsset: (state, action) => {
      const index = state.assets_list.findIndex(
        (obj) => obj.id === action.payload.id
      );

      if (index !== -1) {
        // If the object is found, replace it with the new object
        state.assets_list[index] = action.payload;
      } else {
        // If the object is not found, push the new object into the array
        state.assets_list.push(action.payload);
      }
      // state.assets_list.push(action.payload);
    },
    addAssetatIndex: (state, action) => {
      // console.log(action.payload);
      // console.log("new: " + action.payload.newAsset);
      const index = state.assets_list.findIndex(
        (obj) => obj.id === action.payload.selectedAsset.id
      );

      if (index !== -1) {
        // If the object is found, replace it with the new object
        state.assets_list[index] = action.payload.newAsset;
      } else {
        // If the object is not found, push the new object into the array
        state.assets_list.push(action.payload.newAsset);
      }
      // state.assets_list.push(action.payload);
    },
  },
});

export const addAssetAndUpdateRedo = createAsyncThunk(
  "game/addAssetAndUpdateRedo",
  async (asset, { dispatch, getState }) => {
    // First, dispatch the addAsset action to gameSlice
    const state = getState();
    const currentAssets = [...state.game.assets_list];

    const index = currentAssets.findIndex((obj) => obj.id === asset.id);
    if (index !== -1) {
      dispatch(addRedoAssets(currentAssets[index]));
      currentAssets[index] = await asset;
    } else {
      currentAssets.push(asset);
    }
    dispatch(updateAssets_list(currentAssets));
  }
);

export const addAssetAndUpdateUndo = createAsyncThunk(
  "game/addAssetAndUpdateUndo",
  async (asset, { dispatch, getState }) => {
    // First, dispatch the addAsset action to gameSlice
    const state = getState();
    const currentAssets = [...state.game.assets_list];

    const index = currentAssets.findIndex((obj) => obj.id === asset.id);
    if (index !== -1) {
      dispatch(addDeletedAssets(currentAssets[index]));
      currentAssets[index] = await asset;
    } else {
      currentAssets.push(asset);
    }
    dispatch(updateAssets_list(currentAssets));
  }
);

export const {
  updatePrompt,
  updateDisplayMode,
  updateGameDescription,
  updateCode,
  updateAssets_list,
  updateGameID,
  deleteAsset,
  updateBackgroundSplash,
  addAsset,
  updateAssetData,
  addAssetatIndex,
  updatePromptID,
  updateGameTitle,
  resetGameState,
} = gameSlice.actions;

export default gameSlice.reducer;
