import React from "react";
import logo from "../../assets/icons/logo.png";

const SplashScreen = () => {
  return (
    <div
      className="bg-black h-full flex flex-col justify-center items-center"
      style={{ fontFamily: "pixel_digivolve" }}
    >
      <img src={logo} alt="logo" width="10%"></img>
      <div className="flex flex-col items-center">
        <p className="text-lg mt-4 splashText">CREATED WITH</p>
        <p className="text-4xl splashText">AICADE</p>
      </div>
    </div>
  );
};

export default SplashScreen;
