import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../utils/axios";
import star from "../../assets/icons/star.svg";
import starFill from "../../assets/icons/starFill.svg";
import RateThisImg from "../../assets/icons/RateThisImg.svg";
import GameImg from "../../assets/icons/GameImg.svg";
import closeChat from "../../assets/icons/closeChat.svg";

const Rating = ({ onClose }) => {
  const gameId = useSelector((state) => state.game.gameID);
  const [rating, setRating] = useState(0);
  const [hoverValue, setHoverValue] = useState(0);

  useEffect(() => {
    const ratedGames = JSON.parse(localStorage.getItem("ratedGames")) || [];
    if (ratedGames.includes(gameId)) {
      onClose();
    }
  }, [gameId, onClose]);

  const handleStarClick = async (starValue) => {
    setRating(starValue);

    try {
      const response = await axiosInstance.post("/backend/forms/rating", {
        game_id: gameId,
        rating: starValue,
      });

      if (response.code === "HTTP_200_SUCCESS") {
        const ratedGames = JSON.parse(localStorage.getItem("ratedGames")) || [];
        localStorage.setItem(
          "ratedGames",
          JSON.stringify([...ratedGames, gameId])
        );
        onClose();
      }
      setRating(0);
      setHoverValue(0);
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  const handleStarHover = (starValue) => {
    setHoverValue(starValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(0);
  };

  const displayRatingComponent = () => {
    const hasRated = JSON.parse(localStorage.getItem("ratedGames")) || [];
    return !hasRated.includes(gameId);
  };

  useEffect(() => {
    if (rating != 0) {
      onClose();
    }
  }, [rating]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50 ${displayRatingComponent() ? "block" : "hidden"}`}
    >
      <div className="px-15 py-14 rounded-2xl shadow-lg w-96 flex flex-col items-center relative rating-box">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-2 focus:outline-none"
        >
          <img src={closeChat} alt="Close" className="w-6" />
        </button>
        <img src={RateThisImg} alt="Rate This" className="my-3" />
        <img src={GameImg} alt="Rate This" className="my-3" />
        <div className="flex justify-center my-5">
          {[...Array(5)].map((_, index) => {
            const starValue = index + 1;
            const curve = (starValue - 3) * (starValue - 3) * -10;
            return (
              <img
                key={index}
                onClick={() => handleStarClick(starValue)}
                onMouseEnter={() => handleStarHover(starValue)}
                onMouseLeave={handleMouseLeave}
                src={starValue <= (hoverValue || rating) ? starFill : star}
                alt="star"
                className="w-12 cursor-pointer mr-2"
                style={{ marginBottom: `${curve}px` }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Rating;
