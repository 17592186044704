import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsset,
  resetGameState,
  updateAssets_list,
} from "../../store/features/gameSlice";
import {
  addDeletedAssets,
  updateSelectedAsset,
} from "../../store/features/systemSlice";
import { assetLoaderGif, deleteIcon } from "../../assets";
import Image from "../../utils/Image";
import { posthogCapture } from "../../utils/posthogCapture";
import { useEffect, useState } from "react";
import {
  deleteImageAsset,
  updateImageAssets,
} from "../../store/features/imageAssetsSlice";
import { updateSoundAssets } from "../../store/features/soundAssetsSlice";
import PopUp from "../../components/PopUp/PopUp";
import { imageUploadIcon, plusIcon } from "../../assets";
import EditAssetModel from "../../components/EditAssetModel";
import editIcon from "../../assets/icons/image_editor/edit_icon.svg";
import UploadAssets from "./UploadAssets";

const ImageAssets = () => {
  const imageAssets = useSelector((state) => state.imageAssets.imageAssets);
  const selectedAsset = useSelector((state) => state.system.selectedAsset);
  const isAssetLoading = useSelector((state) => state.system.isAssetLoading);
  const dispatch = useDispatch();
  const [selectedAssetLoading, setSelectedAssetLoading] = useState("");
  const [showEditImage, setShowEditImage] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [currImage, setCurrImage] = useState("");
  const [isUpload, setIsUpload] = useState(false);

  const onClickImage = (asset) => {
    if (asset.id === selectedAsset?.id) {
      dispatch(updateSelectedAsset(""));
    } else {
      dispatch(updateSelectedAsset(asset));
    }
  };

  useEffect(() => {
    if (!isAssetLoading) {
      setSelectedAssetLoading(selectedAsset?.id);
    }
  }, [selectedAsset]);

  const handleOnDrag = (e, dragAsset) => {
    const dragAssetString = JSON.stringify(dragAsset);
    e.dataTransfer.setData("dragAsset", dragAssetString);
  };

  const handleOnDrop = (e, dropAsset) => {
    let dragAssetString = e.dataTransfer.getData("dragAsset");
    let dragAsset = JSON.parse(dragAssetString);
    // console.log(assetImg);
    // console.log("the asset at drop is", dropAsset);
    // console.log(dragAsset.url);
    let updatedAssets = imageAssets.map((eachAsset) => {
      if (dropAsset.id === eachAsset.id) {
        return {
          ...dragAsset,
          id: eachAsset.id,
          name: eachAsset.name,
          category: eachAsset.category,
        };
      } else if (dragAsset.id === eachAsset.id) {
        return {
          ...dropAsset,
          id: eachAsset.id,
          name: eachAsset.name,
          category: eachAsset.category,
        };
      } else {
        return eachAsset;
      }
    });

    posthogCapture("ASSET_DRAG_AND_DROPPED", {
      new_asset: dragAsset,
      old_asset: dropAsset,
    });
    dispatch(updateImageAssets(updatedAssets));
    dispatch(updateSelectedAsset(""));
    // console.log({ updatedAssets_List });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleUploadClick = () => {
    setShowUploadImage(true);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mx-4 mb-5">
        <div key="upload_asset" className="cursor-pointer">
          <div
            className={`relative group h-40 p-1 bg-[#1E2026] rounded-md flex flex-col justify-around items-center transition-all duration-100 ease-linear`}
            id="imgDiv"
            onClick={handleUploadClick}
          >
            <div className="w-24 h-24 flex items-center justify-center rounded-md">
              <img src={plusIcon} className="w-16 h-16" alt="loader" />
            </div>
            <p className="text-white text-xs">Upload</p>
          </div>
        </div>
        {imageAssets.map((asset) => (
          <div key={asset.id}>
            <div
              className={`relative group h-40 p-1 bg-[#1E2026] rounded-md flex flex-col justify-around items-center ${
                selectedAsset?.id === asset?.id
                  ? "border-[4px] border-[#2E40DE]"
                  : ""
              } transition-all duration-100 ease-linear`}
              id="imgDiv"
              onClick={() => onClickImage(asset)}
              draggable
              onDragStart={(e) => handleOnDrag(e, { ...asset })}
              onDrop={(e) => handleOnDrop(e, { ...asset })}
              onDragOver={handleDragOver}
            >
              <DeleteAssetBtn imageID={asset.id} deleteAsset={asset} />
              {isAssetLoading && selectedAssetLoading === asset.id ? (
                <div className="w-24 h-24 flex items-center justify-center rounded-md">
                  <img
                    src={assetLoaderGif}
                    className="w-10 h-10"
                    alt="loader"
                  />
                </div>
              ) : (
                <>
                  <Image
                    src={asset.url}
                    classNames={`w-24 h-24 rounded-md box-border`}
                    alt="asset"
                    isSelected={asset.isSelected}
                  />
                  <img
                    src={editIcon}
                    alt="cropIcon"
                    className="absolute top-1 left-1 cursor-pointer"
                    width="20px"
                    height="20px"
                    onClick={() => {
                      setCurrImage(asset.id);
                      setShowEditImage(true);
                    }}
                  ></img>
                </>
              )}
              <p className="text-white text-xs">
                {asset?.category?.length > 15
                  ? asset.category.substring(0, 15) + "..."
                  : asset.category}
              </p>
            </div>
          </div>
        ))}
      </div>
      {showEditImage && (
        <PopUp show={showEditImage} setShow={setShowEditImage} onClose={true}>
          <EditAssetModel
            currImage={currImage}
            setShow={setShowEditImage}
            assetArray={imageAssets}
            isUpload={isUpload}
            setIsUpload={setIsUpload}
          />
        </PopUp>
      )}
      {showUploadImage && (
        <PopUp
          show={showUploadImage}
          setShow={setShowUploadImage}
          onClose={true}
        >
          <UploadAssets
            setShow={setShowUploadImage}
            setShowEditImage={setShowEditImage}
            setIsUpload={setIsUpload}
            setCurrImage={setCurrImage}
          />
        </PopUp>
      )}
    </>
  );
};

export default ImageAssets;

// const HoverName = ({ keyName }) => {
//   return (
//     <div className="absolute left-0 right-0 items-center justify-center hidden duration-500 -top-12 group-hover:flex">
//       <div className="bg-[#181C20] rounded-md py-2 px-5 shadow shadow-gray-800">
//         <div>
//           <h1 className="mt-1 text-sm text-[#9B9C9E]">{keyName}</h1>
//         </div>
//       </div>
//     </div>
//   );
// };

const DeleteAssetBtn = ({ deleteAsset }) => {
  const dispatch = useDispatch();

  const onClickDeleteAssetBtn = () => {
    dispatch(deleteImageAsset(deleteAsset.id));
    dispatch(addDeletedAssets(deleteAsset));
  };

  return (
    <>
      <img
        src={deleteIcon}
        alt="deleteIcon"
        className="absolute hidden h-5 text-red-500 cursor-pointer right-4 group-hover:flex top-2"
        onClick={onClickDeleteAssetBtn}
      />
    </>
  );
};
