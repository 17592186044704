import { Link } from "react-router-dom";
import { logo } from "../../assets";
import CodeEditor from "../gameRender/CodeEditor";
import { useEffect, useRef } from "react";
import axios from "axios";
import { globalFunctionsURL, saveGameCodeURL } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { updateCode } from "../../store/features/gameSlice";
import axiosInstance from "../../utils/axios";

const FullScreenEditor = () => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    getGlobalFunctions();
  }, []);

  const getGlobalFunctions = async () => {
    try {
      const response = await axios.get(globalFunctionsURL);
      dispatch(updateCode(response.data));
      // console.log(response.data);
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <div>
      <Header />
      <div className="mx-3 mt-3 py-12 px-8 bg-black rounded-[12px] ">
        <div className="py-10 bg-[#1B1C1C] rounded-[12px]">
          <CodeEditor editorRef={editorRef} />
        </div>
      </div>
    </div>
  );
};

export default FullScreenEditor;

const Header = () => {
  return (
    <header className="p-6 mx-3 mt-3 text-white bg-bgPrimary rounded-xl">
      <div className="flex items-center justify-between">
        <Link to="/">
          <img src={logo} alt="Braincade Logo" className="w-12 h-12" />
        </Link>
        <h1 className="text-lg font-semibold text-center">
          Global Functions <span className="ml-1">/</span>{" "}
          <span className="ml-2 text-[#7B7B7B]">Draft</span>
        </h1>
        <div className="flex items-center justify-center gap-4">
          <SaveBtn />
        </div>
      </div>
    </header>
  );
};

// const CodeEditor = () => {
//   return <div>CodeEditor</div>;
// };

const SaveBtn = () => {
  const game_code = useSelector((state) => state.game.code);
  const saveCodeAPI = async () => {
    const formData = new FormData();
    // const game_code = removeAssetsList(code);
    formData.append("game_id", "gameID");
    formData.append("game_code", game_code);
    formData.append("type", "global");
    formData.append("code_file_url", globalFunctionsURL);

    try {
      const response = await axiosInstance.post(saveGameCodeURL, formData);
      // console.log({ response });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <button
      onClick={() => saveCodeAPI()}
      className="flex min-w-[137px] h-12 justify-center items-center rounded-[8px] px-4 py-3.5 text-white text-center text-sm font-semibold leading-5 tracking-[0.15px] btn-tertiary"
    >
      Save
    </button>
  );
};
