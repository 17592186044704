import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axios";
import {
  updateIsLoading,
  updateGameGenerationAPIError,
  updateGameIdeaGenerationAPIError,
  updateQueueLength,
  updateIsGameGenerating,
} from "../store/features/systemSlice";

import { gameGenerationUrl } from "../constants";
import posthog from "posthog-js";
import { posthogCapture } from "../utils/posthogCapture";

const useGameData = () => {
  const displayMode = useSelector((state) => state.game.displayMode);
  const gameDescription = useSelector((state) => state.game.gameDescription);
  const promptID = useSelector((state) => state.game.promptID);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getGameData = useCallback(async () => {
    dispatch(updateGameGenerationAPIError(""));
    dispatch(updateGameIdeaGenerationAPIError(""));
    dispatch(updateIsLoading(true));
    dispatch(updateIsGameGenerating(true));
    const url = gameGenerationUrl;
    const data = {
      game_description: gameDescription,
      orientation: "landscape",
      prompt_id: promptID,
    };

    try {
      posthogCapture("GENERATE_BUTTON_API_CALLED", { request_data: data });
      const response = await axiosInstance.post(url, data);
      // console.log("Game Project Response:", response.data.data.game_id);
      const game_id = response.data.data.game_id;
      dispatch(updateQueueLength(response.data.data.queue_length + 1));
      posthogCapture("GENERATE_BUTTON_API_SUCCESS", {
        response: response.data.data,
      });
      navigate(`/edit/${game_id}`);
    } catch (error) {
      // console.error("Error:", error);

      posthogCapture("GENERATE_BUTTON_API_FAILURE", {
        response: error.response.data,
      });
      dispatch(
        updateGameGenerationAPIError(
          `An ${error.message} occurred while generating the game. Please try again.`
        )
      );
      dispatch(updateIsLoading(false));
      // navigate("/render-game");
    }
  }, [dispatch, navigate, gameDescription, displayMode, promptID]);

  return getGameData;
};

export default useGameData;
