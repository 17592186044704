import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assetIterationTypeValues,
  phaser2URL,
  phaser3URL,
  sideBarType,
} from "../../constants";

const initialState = {
  currentURL: "https://play.aicade.in/",
  assetIterationTypes: assetIterationTypeValues,
  lastDisplayedStatusIndex: -1,
  loaderMessage: ["Your game is getting ready"],
  uploadedImage: {},
  gameGenerationAPIError: "",
  gameIdeaGenerationAPIError: "",
  gameAssetIterationAPIError: "",
  promptValue: "",
  isLoading: false,
  isGameGenerating: false,
  sideBarType: sideBarType.images,
  // isLoggedIn: false,
  deletedAssets: [],
  redoAssets: [],
  phaserVersion: "pv3",
  phaserURL: phaser3URL,
  selectedAsset: {},
  isAssetLoading: false,
  gameDisplayCode: "",
  queueLength: 0,
  assetLoaderDict: {},
  selectedAssetIterationType: null,
  displayAssetOptions: false,
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    updateCurrentURL: (state, action) => {
      state.currentURL = action.payload;
    },
    updateAssetIterationTypes: (state, action) => {
      state.assetIterationTypes = action.payload;
    },
    updateDisplayAssetOptions: (state, action) => {
      state.displayAssetOptions = action.payload;
    },
    updateSideBarType: (state, action) => {
      state.sideBarType = action.payload;
    },
    updateSelectedAssetIterationType: (state, action) => {
      state.selectedAssetIterationType = action.payload;
    },
    updateLastDisplayedStatusIndex: (state, action) => {
      state.lastDisplayedStatusIndex = action.payload;
    },
    updateLoaderMessage: (state, action) => {
      state.loaderMessage = action.payload;
    },
    updateAssetLoaderDict: (state, action) => {
      state.assetLoaderDict = action.payload;
    },
    updateGameGenerationAPIError: (state, action) => {
      state.gameGenerationAPIError = action.payload;
    },
    updateGameAssetIterationAPIError: (state, action) => {
      state.gameAssetIterationAPIError = action.payload;
    },
    updateGameDisplayCode: (state, action) => {
      state.gameDisplayCode = action.payload;
    },
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateIsGameGenerating: (state, action) => {
      state.isGameGenerating = action.payload;
    },
    updateGameIdeaGenerationAPIError: (state, action) => {
      state.gameIdeaGenerationAPIError = action.payload;
    },
    updateQueueLength: (state, action) => {
      state.queueLength = action.payload;
    },
    updatePromptValue: (state, action) => {
      state.promptValue = action.payload;
    },
    // logOffUser: (state) => {
    //   state.isLoggedIn = false;
    // },
    // logInUser: (state) => {
    //   state.isLoggedIn = true;
    // },
    addDeletedAssets: (state, action) => {
      state.deletedAssets.push(action.payload);
    },
    emptyDeletedAssets: (state, action) => {
      state.deletedAssets = [];
    },
    addRedoAssets: (state, action) => {
      state.redoAssets.push(action.payload);
    },
    removeRedoAssets: (state) => {
      state.redoAssets.pop();
    },
    emptyRedoAssets: (state) => {
      state.redoAssets = [];
    },
    removeDeletedAsset: (state) => {
      state.deletedAssets.pop();
    },
    updateSelectedAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    updateIsAssetLoading: (state, action) => {
      state.isAssetLoading = action.payload;
    },
    updatePhaserVersion: (state, action) => {
      state.phaserVersion = action.payload;
    },
    updateUploadedImage: (state, action) => {
      state.uploadedImage = action.payload;
    },

    updatePhaserURL: (state) => {
      switch (state.phaserVersion) {
        case "pv3":
          state.phaserURL = phaser3URL;
          break;
        case "pv2":
          state.phaserURL = phaser2URL;
          break;
        default:
          state.phaserURL = phaser3URL;
      }
    },
  },
});

export const emptyUndoAndRedo = createAsyncThunk(
  "game/emptyUndoAndRedo",
  async (asset, { dispatch, getState }) => {
    dispatch(emptyRedoAssets());
    dispatch(emptyDeletedAssets());
  }
);

export const {
  updateGameGenerationAPIError,
  updateGameAssetIterationAPIError,
  updateGameDisplayCode,
  updateIsLoading,
  updateGameIdeaGenerationAPIError,
  updatePromptValue,
  updateSideBarType,
  // logOffUser,
  // logInUser,
  addDeletedAssets,
  removeDeletedAsset,
  updatePhaserVersion,
  updatePhaserURL,
  updateSelectedAsset,
  updateIsAssetLoading,
  addRedoAssets,
  removeRedoAssets,
  emptyRedoAssets,
  emptyDeletedAssets,
  updateUploadedImage,
  updateQueueLength,
  updateAssetLoaderDict,
  updateLoaderMessage,
  updateLastDisplayedStatusIndex,
  updateIsGameGenerating,
  updateSelectedAssetIterationType,
  updateDisplayAssetOptions,
  updateAssetIterationTypes,
  updateCurrentURL,
} = systemSlice.actions;

export default systemSlice.reducer;
