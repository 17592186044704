import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axios";

import {
  updateIsLoading,
  updateGameIdeaGenerationAPIError,
  updatePromptValue,
  updateGameGenerationAPIError,
  updateLoaderMessage,
} from "../store/features/systemSlice";
import {
  updateGameDescription,
  updatePromptID,
} from "../store/features/gameSlice";

import { gameIdeaGenerationUrl } from "../constants";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { posthogCapture } from "../utils/posthogCapture";

const useGameDescription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const generateGameDescription = async (prompt, displayMode, navigatePage) => {
    dispatch(updateGameGenerationAPIError(""));
    dispatch(updateGameIdeaGenerationAPIError(""));
    dispatch(updateIsLoading(true));
    dispatch(updateLoaderMessage(["Creating Game Idea"]));
    dispatch(updatePromptValue(""));
    const url = gameIdeaGenerationUrl;
    const data = {
      prompt: prompt,
      orientation: displayMode ? "landscape" : "portrait",
    };

    try {
      posthogCapture("GENERATE_IDEA_BUTTON_API_CALLED", {
        request_data: data,
      });
      const response = await axiosInstance.post(url, data);
      dispatch(updatePromptValue(""));
      dispatch(
        updateGameDescription(response.data.data.ai_response.description)
      );
      // console.log(response);
      // console.log("Game Description Response:", response.data);
      dispatch(updatePromptID(response.data.data.prompt_id));
      dispatch(updateIsLoading(false));

      posthogCapture("GENERATE_IDEA_BUTTON_API_SUCCESS", {
        response: response.data.data,
      });
      if (navigatePage) {
        navigate(navigatePage);
      }
    } catch (error) {
      console.error("Error:", error);
      posthogCapture("GENERATE_IDEA_BUTTON_API_SUCCESS", {
        error: error.response.data,
      });
      dispatch(
        updateGameIdeaGenerationAPIError(
          `An ${error.message} occurred while generating the game description. Please try again.`
        )
      );
      dispatch(updateIsLoading(false));
    }
  };

  return generateGameDescription;
};

export default useGameDescription;
