import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import convertToAssetDict from "../../utils/convertToAssetsLoader";
import {
  updateGameDisplayCode,
  updateIsLoading,
} from "../../store/features/systemSlice";
import { useDispatch, useSelector } from "react-redux";
import { displayScreenConstants, gameSaveURL } from "../../constants";
import RotateDevicePrompt from "../../utils/RotateDevicePrompt";
import DisplayGame from "../gameRender/DisplayGame";
import axiosInstance from "../../utils/axios";
import { posthogCapture } from "../../utils/posthogCapture";
import { updateDisplayScreen } from "../../store/features/gameDisplaySlice";
import {
  updateGameDescription,
  updateGameID,
  updateGameTitle,
} from "../../store/features/gameSlice";
import { updateImageAssets } from "../../store/features/imageAssetsSlice";
import { updateSoundAssets } from "../../store/features/soundAssetsSlice";
import useCreateGameCode from "../../hooks/useCreateGameCode";
import convertToBlobUrls from "../../utils/convertToBlobUrls";

const ShareGame = () => {
  const createGameCode = useCreateGameCode();

  const displayMode = useSelector((state) => state.game.displayMode);
  const gameDisplayCode = useSelector((state) => state.system.gameDisplayCode);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const updateSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    posthogCapture("PLAY_SCREEN_VIEWED");
    fetchGameData();
  }, []);

  const fetchGameData = async () => {
    // dispatch(updateIsLoading(true));
    dispatch(updateDisplayScreen(displayScreenConstants.splash));
    const gameId = location.pathname.split("/play/")[1];
    dispatch(updateGameID(gameId));
    const apiEndpoint = `${gameSaveURL}?game_id=${gameId}`;
    // const apiEndpoint = "http://localhost:3000/api/game_info";

    try {
      const response = await axiosInstance.get(apiEndpoint);
      // console.log("Game Project Response:", response);
      const { game_id, game_title, game_description, assets, game_code_url } =
        response.data.data;
      const { images, sounds } = assets;

      // console.log({ sounds });

      const updatedSounds = sounds.map((item) => {
        if (
          item.category === "background" &&
          (item.url === "" || item.url === "background")
        ) {
          return {
            ...item,
            url: "https://aicade-ui-assets.s3.amazonaws.com/GameAssets/music/bgm-4.mp3",
          };
        } else {
          return { ...item };
        }
      });
      // console.log({ updatedSounds });
      const imageAssetsWithBlobUrl = await convertToBlobUrls(images);
      const soundAssetsWithBlobUrl = await convertToBlobUrls(updatedSounds);
      // console.log(imageAssetsWithBlobUrl);
      dispatch(updateImageAssets(imageAssetsWithBlobUrl));
      dispatch(updateSoundAssets(soundAssetsWithBlobUrl));
      dispatch(updateGameDescription(game_description));
      dispatch(updateGameTitle(game_title));
      dispatch(updateGameID(game_id));

      const { data } = await axios.get(game_code_url);
      const gameCode = data;

      // console.log({ images });
      createGameCode(imageAssetsWithBlobUrl, soundAssetsWithBlobUrl, gameCode);
      dispatch(updateIsLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(updateIsLoading(false));
    }
  };

  useEffect(() => {
    function checkOrientation() {
      const regex = /const gameOrientation = "(.*?)"/;
      const match = gameDisplayCode.match(regex);
      const gameOrientationValue = match ? match[1] : "null";
      let condition;
      switch (gameOrientationValue[0].toLocaleLowerCase()) {
        case "l":
          condition = window.innerWidth < window.innerHeight;
          break;
        case "p":
          condition = window.innerWidth > window.innerHeight;
          break;
        default:
          condition = false;
      }

      var overlay = document.getElementById("rotate-device-overlay");
      var gameContainer = document.getElementById("game-container"); // game display container ID

      if (overlay && gameContainer) {
        if (condition) {
          overlay.style.display = "flex";
        } else {
          overlay.style.display = "none";
        }
      }
    }
    checkOrientation();
  }, [screenSize, gameDisplayCode]);

  return (
    <div>
      <div
        className={`relative w-screen h-screen overflow-hidden ${!displayMode && "flex justify-center items-center"}`}
      >
        <RotateDevicePrompt />
        {displayMode ? (
          <DisplayGame screenSize={screenSize} />
        ) : (
          <div className=" h-screen w-[405px]">
            <DisplayGame screenSize={screenSize} />
          </div>
        )}
        {/* <DisplayGame screenSize={screenSize} /> */}
      </div>
    </div>
  );
};

export default ShareGame;
