import React from "react";
import "./GameScreen.css";
import { updateDisplayScreen } from "../../store/features/gameDisplaySlice";
import { displayScreenConstants } from "../../constants";
import { useDispatch, useSelector } from "react-redux";

const StartScreen = ({ dataObject, handleOnClickStart, screenSize }) => {
  const dispatch = useDispatch();
  const handleStartGame = () => {
    dispatch(updateDisplayScreen(displayScreenConstants.game));
  };
  const background_splash = useSelector(
    (state) => state.game.background_splash
  );

  return (
    <div
      className={`text-center flex justify-center items-center h-full flex-1`}
      style={{
        backgroundImage: `url(${background_splash})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        textShadow: "-1px 2px 0 rgba(0, 0, 0, 0.518)",
      }}
    >
      <div
        className="flex flex-col justify-around items-center h-4/5"
        style={{ margin: "3%", fontFamily: "pixel_digivolve" }}
      >
        <div className="mainTextTitle text-white text-4xl font-medium">
          {dataObject.name}
        </div>
        <div className="mainTextDesc text-base h-fit font-medium w-4/5 text-white flex flex-col items-center justify-between">
          {dataObject.description}
          <button
            onClick={handleOnClickStart}
            className="startButton mt-4 bg-no-repeat bg-center bg-contain w-28 h-12 flex justify-center items-center cursor-pointer"
          >
            <p
              className="text-xl text-white font-medium"
              style={{ paddingBottom: "8%" }}
            >
              {dataObject.buttonName}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
