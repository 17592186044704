import React, { useState, useEffect } from "react";
import {
  eyeGif,
  rightTickIcon,
  rightTickGreenIcon,
  gameLoaderGif,
  assetLoaderGif,
} from "../assets";
import loaderCircle from "../assets/image/loaderCircle.png";
import { useSelector } from "react-redux";
import { gameGenerationStatuses } from "../constants";
import { posthogCapture } from "../utils/posthogCapture";

const Loader = () => {
  const [progress, setProgress] = useState(0);
  const isGameGenerating = useSelector(
    (state) => state.system.isGameGenerating
  );
  const [statuses, setStatuses] = useState(gameGenerationStatuses);
  let loaderMessage = useSelector((state) => state.system.loaderMessage);
  useEffect(() => {
    const progressIntervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        if (newProgress >= 100) {
          setProgress(0);
        }
        return newProgress;
      });
    }, 100);

    return () => {
      clearInterval(progressIntervalId);
    };
  }, [loaderMessage]);

  useEffect(() => {
    const updateActiveStatus = (name) => {
      const currentStatus = gameGenerationStatuses.filter(
        (each) => each.name === name
      );

      if (currentStatus.length > 0) {
        // Check if the array is not empty
        const updatedObjects = statuses.map((obj) => {
          if (currentStatus[0].name === "done") {
            return { ...obj, isActive: false, isCompleted: true };
          }
          if (obj.index < currentStatus[0].index) {
            return { ...obj, isActive: false, isCompleted: true };
          } else if (obj.index === currentStatus[0].index) {
            return { ...obj, isActive: true };
          }
          return obj;
        });
        setStatuses(updatedObjects);
      }
    };

    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const timeoutFunc = async () => {
      if (loaderMessage) {
        for (let i = 0; i < loaderMessage.length; i++) {
          const status = loaderMessage[i];
          if (i === loaderMessage.length - 1) {
            updateActiveStatus(status);
          } else {
            updateActiveStatus(status);
            await wait(3300);
          }
        }
      }
    };

    timeoutFunc();
  }, [loaderMessage]);

  return (
    <>
      {isGameGenerating ? (
        <StepLoader statuses={statuses} progress={progress} />
      ) : (
        <div class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-90 z-50">
          <img
            src={loaderCircle}
            alt="Loading..."
            class="loader-circle-image"
          />
          <div class="text-white text-center mt-4 loading-text">
            {loaderMessage[0]}
          </div>
        </div>
      )}
      {/* (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
          <div className="h-[200px] w-[484px] loader-gradient flex p-12 pl-16 items-center rounded-[23px]">
            <div className="rounded-[8px] p-[10px] loader-icon-gradient h-[90px] w-[90px] mr-6">
              <img src={eyeGif} alt="eyeGif" />
            </div>

            <div className="w-full">
              <h1 className="text-[#9B9C9E]">{loaderMessage[0]}</h1>
              <div className="mt-6 bg-[#5C5C5C] height-[2px]  w-60">
                <div
                  className="border-2 border-blue-500"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Loader;

const StepLoader = ({ statuses, progress }) => {
  useEffect(() => {
    posthogCapture("GAME_GENERATION_LOADING_SCREEN_VIEWED");
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
      <div className="h-[300px] w-[640px] loader-gradient flex p-12 pl-16 items-center rounded-[23px] justify-between">
        <img
          src={gameLoaderGif}
          alt="eyeGif"
          className="rounded-[16px] w-[50%]"
        />
        <ul>
          {statuses.map((eachStatus) => (
            <>
              <li
                key={eachStatus.name}
                style={{ listStyleType: "none", margin: "10px 0" }}
                className="text-[#9B9C9E]"
              >
                <label
                  className={`${eachStatus.isActive ? "text-white" : "text-[#9B9C9E]"} flex items-center`}
                >
                  {eachStatus.isActive ? (
                    <img
                      src={assetLoaderGif}
                      className="fill-black mr-3"
                      style={{ height: "16px", width: "16px" }}
                      alt="grey text"
                    />
                  ) : (
                    <>
                      {eachStatus.isCompleted ? (
                        <img
                          src={rightTickGreenIcon}
                          className="fill-black mr-3"
                          alt="grey text"
                        />
                      ) : (
                        <img
                          src={rightTickIcon}
                          className="fill-black mr-3"
                          alt="green text"
                        />
                      )}
                    </>
                  )}

                  {eachStatus.displayText}
                </label>
              </li>
            </>
          ))}
        </ul>
      </div>
    </div>
  );
};
