const getImageFromAssetList = (assetsList, endsWithString) => {
  for (const asset of assetsList) {
    if (asset?.name?.includes(endsWithString)) {
      return asset.url;
    }
  }
  return null;
};

export default getImageFromAssetList;
