const convertToAssetsLoader = (assetsList) => {
  const assetsLoader = {};
  for (const asset of assetsList) {
    const category = asset.category;
    assetsLoader[category] = asset.blobURL ? asset.blobURL : asset.url;
  }
  return assetsLoader;
};

export default convertToAssetsLoader;
