import { useEffect, useRef, useState } from "react";
import { logo } from "../assets";
import LaunchGame from "./LaunchGame";
import { posthogCapture } from "../utils/posthogCapture";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { updateGameTitle } from "../store/features/gameSlice";

const NewHeader = ({ enableLaunch }) => {
  const [gameName, setGameName] = useState("Draft");
  const [isEditing, setIsEditing] = useState(false);
  const gameTitleRef = useRef(null);

  const dispatch = useDispatch();

  const handleGameTitleClick = () => {
    setIsEditing(true);
  };

  function handleSave() {
    setIsEditing(false);
    dispatch(updateGameTitle(gameName));
  }

  useEffect(() => {
    // useEffect hook to focus on the input when isEditing is true
    if (isEditing && gameTitleRef.current) {
      gameTitleRef.current.focus();
    }
  }, [isEditing]);

  return (
    <header className="px-6 py-2 mx-3 text-white bg-bgPrimary rounded-xl ">
      <div className="flex items-center justify-between">
        <a href="/" onClick={() => posthogCapture("HOME_LOGO_CLICKED")}>
          <img src={logo} alt="Braincade Logo" className="w-10" />
        </a>
        <h1 className="text-[14px] font-semibold text-center flex items-center">
          <span>Demo Project </span>
          <span className="ml-1">/</span>{" "}
          {isEditing ? (
            <div className="flex items-center ml-2">
              <input
                ref={gameTitleRef}
                type="text"
                value={gameName}
                onChange={(event) => {
                  setGameName(event.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSave();
                  }
                }}
                placeholder="Enter Game Name"
                className="bg-transparent outline-none placeholder:text-[#545454] font-medium text-white rounded-lg"
              />
            </div>
          ) : (
            <span
              className="header-game-title ml-2 text-[#7B7B7B]"
              onClick={handleGameTitleClick}
            >
              {gameName}
            </span>
          )}
        </h1>
        <div className="flex items-center justify-center gap-4">
          {/* <CreditInfo /> */}
          <FeedBackButton />
          {enableLaunch && <LaunchButton />}
          <LogoutButton />
        </div>
      </div>
    </header>
  );
};

// const CreditInfo = () => (
//   <div className="flex flex-col items-end w-full gap-3">
//     <h1 className="text-xs">
//       <span className="font-semibold">400</span> Credits remaining
//     </h1>
//     <div className="w-full bg-white rounded-full">
//       <div className="w-4/12 h-2 bg-green-500 rounded-full"></div>
//     </div>
//   </div>
// );

const FeedBackButton = () => {
  return (
    <button
      className="flex items-center justify-between font-semibold h-12 whitespace-nowrap form-button"
      onClick={() => {
        window.open("/feedback");
      }}
    >
      <h1 className="w-full text-sm">Free Month Awaits</h1>
    </button>
  );
};

const LaunchButton = () => {
  const [isLaunchGameOpen, setIsLaunchGameOpen] = useState(false);
  const onClickCloseLaunchModel = () => {
    setIsLaunchGameOpen(false);
  };
  return (
    <>
      {isLaunchGameOpen && (
        <LaunchGame onClickCloseLaunchModel={onClickCloseLaunchModel} />
      )}

      <button
        onClick={() => setIsLaunchGameOpen(true)}
        className="flex min-w-[137px] h-12 justify-center items-center rounded-[8px] px-4 py-3.5 text-white text-center text-sm font-semibold leading-5 tracking-[0.15px] btn-tertiary"
      >
        Launch
      </button>
    </>
  );
};

const LogoutButton = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove("userEmail");
    Cookies.remove("userToken");
    navigate("/");
  };
  return (
    <button
      onClick={handleLogout}
      className="border-2 border-gray-500 hover:border-red-700 hover:text-red-700 px-5 py-3.5 rounded-md h-12 text-white-600 hover:text-red-00 hover:bg-black bg-gray-600 bg-opacity-25 hover:bg-opacity-40 flex items-center text-sm justify-between gap-2 font-semibold duration-300 whitespace-nowrap"
    >
      Logout
    </button>
  );
};

export default NewHeader;
