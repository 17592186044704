import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axiosInstance from "../../utils/axios";
import posthog, { PostHog } from "posthog-js";
import { posthogCapture } from "../../utils/posthogCapture";
import { useSelector } from "react-redux";
import EyeOpen from "../../assets/image/eye-open.png";
import EyeClose from "../../assets/image/eye-close.png";
import Logo from "./../../assets/image/logo.png";

const Login = () => {
  const currentURL = useSelector((state) => state.system.currentURL);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const userToken = Cookies.get("userToken");

  useEffect(() => {
    posthogCapture("LOGIN_PAGE_VIEWED");
  }, []);

  const location = useLocation();
  if (userToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const onClickSubmitForm = (event) => {
    event.preventDefault();
    posthogCapture("LOGIN_BUTTON_CLICKED");
    loginUser(email, password);
  };

  const loginUser = async (email, password) => {
    try {
      const response = await axiosInstance.post("/backend/user/login", {
        email: email,
        password: password,
      });

      // Handle response here
      const userToken = response.data.data.id;
      Cookies.set("userToken", userToken, { expires: 0.5 });
      Cookies.set("userEmail", email, { expires: 0.5 });
      posthogCapture("LOGIN_SUCCESS", { email, user_id: userToken });
      // console.log(currentURL);
      if (currentURL.includes("edit")) {
        navigate(currentURL);
      } else {
        navigate("/");
      }
    } catch (error) {
      // Handle error here
      console.error("Error during API call", error.response.data);
      // setLoginError(true);
      setLoginError(error.response.data.message);
      posthogCapture("LOGIN_FAILURE", { email, error: error.response.data });

      // navigate("/chat");
    }
  };

  return (
    <div class="flex min-h-screen flex-col justify-center items-center ">
      <div className="w-[420px] rounded-[12px] px-6 py-12 lg:px-8 bg-[#222427]">
        <img src={Logo} alt="Logo" className="mx-auto h-20 w-20" />
        <div class="sm:mx-auto sm:w-full sm:max-w-sm ">
          {/* <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"/> */}
          <h2 class="text-center mt-2 text-2xl font-bold leading-9 tracking-tight text-[#D3D3D3]">
            Sign in to your account
          </h2>
        </div>

        <div class="mt-7 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-4" method="POST" onSubmit={onClickSubmitForm}>
            <div className="space-y-1">
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-[#9B9C9E]"
              >
                Email
              </label>
              <div class="mt-2">
                <input
                  value={email}
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  className="bg-transparent text-white border border-[#545454] rounded-md w-full h-9 px-3"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>

            <div className="space-y-1">
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm font-medium leading-6 text-[#9B9C9E]"
                >
                  Password
                </label>
                {/* <div class="text-sm">
                <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
              </div> */}
              </div>
              <div className="mt-2 relative">
                <input
                  value={password}
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  className="bg-transparent text-white border rounded-md w-full h-9 px-3 border-[#545454] pr-10"
                  onChange={(event) => setPassword(event.target.value)}
                />
                <button
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-transparent border-none focus:outline-none"
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                >
                  {showPassword ? (
                    <img
                      src={EyeClose}
                      alt="Hide Password"
                      className="h-6 w-6"
                    />
                  ) : (
                    <img
                      src={EyeOpen}
                      alt="Show Password"
                      className="h-6 w-6"
                    />
                  )}
                </button>
              </div>
            </div>

            <div>
              <button
                type="submit"
                class="flex w-full justify-center rounded-md bg-gradient-to-br to-[#6049E6] from-[#3416AC] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
          {loginError ? (
            <p className="text-red-500 mt-3">{loginError}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
