import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { updateCurrentURL } from "../store/features/systemSlice";

const ProtectedRoute = ({ children }) => {
  const userToken = Cookies.get("userToken");
  const location = useLocation();
  const dispatch = useDispatch();
  dispatch(updateCurrentURL(location.pathname));
  if (!userToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
