const convertToBlobUrls = async (images) => {
  const updatedImages = [];

  for (let i = 0; i < images.length; i++) {
    // console.log(images[i]);
    let blobURL = images[i].url;
    try {
      const response = await fetch(images[i].url);
      const blob = await response.blob();
      blobURL = URL.createObjectURL(blob);
      // console.log(blob);
      updatedImages.push({
        ...images[i],
        blobURL: blobURL,
      });
    } catch (error) {
      // console.log("executed");
      updatedImages.push({
        ...images[i],
        blobURL: blobURL,
      });
      console.error(`Error fetching image for ${images[i].name}:`, error);
    }
  }
  // console.log(updatedImages);
  return updatedImages;
};

export default convertToBlobUrls;
