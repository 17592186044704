import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateDisplayMode,
  updatePrompt,
} from "../../store/features/gameSlice";
import {
  updateGameIdeaGenerationAPIError,
  updatePromptValue,
} from "../../store/features/systemSlice";

import Header from "../../components/Headers";
import PromptBar from "../../components/PromptBar";
import HoverCard from "./HoverCard";

import {
  lightBulbIcon,
  landscapeModeIcon,
  portraitModeIcon,
  magicWandIcon,
  rightArrowIcon,
} from "../../assets";

import useGameDescription from "../../hooks/useGameDescription";
import { getRandomPrompts } from "../../utils/getRandomPrompts";
import { trendingPrompts } from "../../constants";
import NewHeader from "../../components/NewHeader";
import NewPromptBar from "../../components/NewPromptBar";
import posthog from "posthog-js";
import { posthogCapture } from "../../utils/posthogCapture";

const selectedPrompts = getRandomPrompts(trendingPrompts, 3);

const Home = () => {
  const displayMode = useSelector((state) => state.game.displayMode);
  const promptValue = useSelector((state) => state.system.promptValue);
  const apiError = useSelector(
    (state) => state.system.gameIdeaGenerationAPIError
  );

  const generateDescription = useGameDescription();

  const dispatch = useDispatch();

  useEffect(() => {
    posthogCapture("PROMPT_ENTRY_PAGE_VIEWED");
  }, []);

  const onClickPromptSubmitBtn = () => {
    dispatch(updatePrompt(promptValue));
    generateDescription(promptValue, displayMode, "/chat");
  };

  const onClickTrendingPrompt = (text) => {
    posthogCapture("TRENDING_PROMPTS_CLICKED", { prompt: text });
    dispatch(updatePromptValue(text));
  };

  const onClickMagicWandBtn = () => {
    const selectedPrompt = getRandomPrompts(trendingPrompts, 1);
    posthogCapture("SURPRISE_ME_BUTTON_CLICKED", { prompt: selectedPrompt });
    dispatch(updatePromptValue(selectedPrompt[0]));
  };

  useEffect(() => {
    dispatch(updateGameIdeaGenerationAPIError(""));
  }, []);

  return (
    <div className="h-screen pt-[1%]">
      <NewHeader />
      <main className="container m-4 mx-auto text-white">
        <section className="text-center">
          <h1 className="py-6 text-3xl font-semibold text-[#D3D3D3]">
            Welcome to the Game Builder
          </h1>
          <p className="text-[#9B9C9E] mx-auto text-lg w-5/12">
            This is where the magic happens. Begin crafting your game and watch
            your story come to life.
          </p>
        </section>
        <section className="w-3/4 mx-auto">
          <h2 className="py-4 text-base font-medium text-[#4F4F4F]">
            Trending Prompts:
          </h2>
          <div className="flex flex-wrap items-center justify-between">
            {selectedPrompts.map((promptText, index) => (
              <PromptCard
                key={promptText}
                text={promptText}
                onClickTrendingPrompt={onClickTrendingPrompt}
              />
            ))}
          </div>
        </section>
        <div className="flex justify-center w-full">
          <section className="absolute bottom-0 left-auto right-auto container mx-auto w-3/4">
            <div className="border-t border-[#ffffff] border-opacity-10 bg-gradient-to-r from-[#191A1B] via-[#1D1D2178] to-[#ffffff12] rounded-t-xl p-4">
              {/* <h1 className="py-4 text-2xl text-center text-[#D3D3D3]">
              Choose Your Canvas Orientation
            </h1>
            <p className="text-[#9B9C9E] text-center mx-auto text-base w-6/12">
              Select your canvas orientation to suit the style and feel of your
              game.
            </p> */}
              <div className="flex justify-center items-center gap-6 my-8">
                <div className="relative z-0 group flex justify-center items-center">
                  <p>Running short of ideas? Use this</p>
                  <img src={rightArrowIcon} className="ml-3" />
                  {/* <HoverCard type={1} /> */}
                  {/* <button
                  onClick={() => dispatch(updateDisplayMode("landscape"))}
                  className={`px-4 py-2 text-white rounded-md flex gap-2 items-center w-52 justify-center ${
                    displayMode === "landscape"
                      ? "bg-gradient-to-br to-[#6049E6] from-[#3416AC]"
                      : "bg-gradient-to-br from-[#8775F889] to-[#9991F428]"
                  }`}
                >
                  Landscape Mode
                  <img src={landscapeModeIcon} alt="landscapeModeIcon" />
                </button> */}
                </div>
                <div className="relative z-0 group">
                  <button
                    onClick={() => onClickMagicWandBtn()}
                    className={`px-4 py-2 text-white rounded-md flex gap-2 items-center w-40 justify-center ${
                      displayMode === "portrait"
                        ? "bg-gradient-to-br to-[#6049E6] from-[#3416AC]"
                        : "bg-gradient-to-br from-[#8775F889] to-[#9991F428]"
                    }`}
                  >
                    Surprise Me
                    <img
                      src={magicWandIcon}
                      alt="portraitModeIcon"
                      className="ml-2"
                    />
                  </button>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="w-3/4">
                  {/* <PromptBar
                    onClickPromptBtn={onClickPromptSubmitBtn}
                    onClickMagicWandBtn={onClickMagicWandBtn}
                  /> */}
                  <NewPromptBar
                    onClickPromptBtn={onClickPromptSubmitBtn}
                    onClickMagicWandBtn={onClickMagicWandBtn}
                  />
                </div>
              </div>
              {apiError && (
                <p className="w-3/4 mx-auto mt-5 text-red-500">{apiError}</p>
              )}
              <p className="text-[#565656] text-[13px] mx-auto mt-5 w-3/4">
                Write captivating character and plot twists that will keep
                players engaged
              </p>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Home;

const PromptCard = ({ text, onClickTrendingPrompt }) => {
  return (
    <div
      className="bg-[#19191B] p-2.5 flex items-start gap-4 border-t-2 rounded-md border-gray-500 text-gray-400 text-sm cursor-pointer"
      style={{ width: "30%" }}
      onClick={() => onClickTrendingPrompt(text)}
    >
      <h1 className="min-h-[100px]">{text}</h1>
      <img src={lightBulbIcon} alt="lightBulbIcon" />
    </div>
  );
};
