import React, { useEffect, useRef, useState } from "react";
import GameRender from "../gameRender/GameRender";
import axios from "axios";
import axiosInstance from "../../utils/axios";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGameAssetIterationAPIError,
  updateGameDisplayCode,
  updateIsGameGenerating,
  updateIsLoading,
  updateLoaderMessage,
} from "../../store/features/systemSlice";
import {
  updateCode,
  updateGameDescription,
  updateGameID,
  updateGameTitle,
} from "../../store/features/gameSlice";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { removeAssetLoader } from "../../utils/removeAssetLoader";
import TutorialScreen from "../tutorialScreen/TutorialScreen";
import convertToAssetsLoader from "../../utils/convertToAssetsLoader";
import { updateImageAssets } from "../../store/features/imageAssetsSlice";
import { updateSoundAssets } from "../../store/features/soundAssetsSlice";
import useCreateGameCode from "../../hooks/useCreateGameCode";
import { gameSaveURL } from "../../constants";
import convertToBlobUrls from "../../utils/convertToBlobUrls";
import convertToBlobUrl from "../../utils/convertToBlobUrl";
let lastIndex = -1;

const EditGame = () => {
  const createGameCode = useCreateGameCode();

  const gameID = useSelector((state) => state.game.gameID);
  const location = useLocation();
  const dispatch = useDispatch();
  const [toastShown, setToastShown] = useState(false);
  const queueLength = useSelector((state) => state.system.queueLength);
  const [firstTime, setFirstTime] = useState(false);
  let intervalId = useRef();

  if (toastShown === false && queueLength !== 0) {
    // toast(`Estimate time is ${queueLength * 12} minutes`);
    // toast(`Game will be ready in few minutes`);
    setToastShown(true);
  }

  useEffect(() => {
    if (!gameID) {
      const fetchInterval = 10000;

      const intervalFunction = async () => {
        try {
          const isSuccessful = await fetchGameData();
          if (isSuccessful) {
            clearInterval(intervalId); // Clear the interval if successful
          }
        } catch (error) {
          console.error("Error in fetchGameData:", error);
          // Error handling logic here
        }
      };

      // Call once immediately and then set the interval
      intervalFunction();
      intervalId = setInterval(intervalFunction, fetchInterval);
    }

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  const fetchGameData = async () => {
    dispatch(updateIsLoading(true));
    dispatch(updateLoaderMessage(["Creating Game"]));
    const gameId = location.pathname.split("/edit/")[1];
    const apiEndpoint = `${gameSaveURL}?game_id=${gameId}`;
    // const apiEndpoint = "http://localhost:3000/api/game_info";

    try {
      const startTime = performance.now();
      const response = await axiosInstance.get(apiEndpoint);
      if (response.status === 200) {
        const endTime = performance.now();
        console.log("Time Taken = ", endTime - startTime);
        clearInterval(intervalId); // Clear interval when 200 response is received

        const { game_id, game_title, game_description, assets, game_code_url } =
          response.data.data;
        const { images, sounds } = assets;

        // console.log({ sounds });
        const backgroundBlobURL = await convertToBlobUrl(
          "https://aicade-ui-assets.s3.amazonaws.com/GameAssets/music/bgm-4.mp3"
        );

        const updatedSounds = sounds.map((item) => {
          if (
            item.category === "background" &&
            (item.url === "" || item.url === "background")
          ) {
            return {
              ...item,
              url: "https://aicade-ui-assets.s3.amazonaws.com/GameAssets/music/bgm-4.mp3",
              blobURL: backgroundBlobURL,
            };
          } else {
            return { ...item };
          }
        });
        // console.log({ updatedSounds });

        const imageAssetsWithBlobUrl = await convertToBlobUrls(images);
        const soundAssetsWithBlobUrl = await convertToBlobUrls(updatedSounds);
        // console.log(imageAssetsWithBlobUrl);
        // console.log(soundAssetsWithBlobUrl);
        dispatch(updateImageAssets(imageAssetsWithBlobUrl));
        dispatch(updateSoundAssets(soundAssetsWithBlobUrl));
        dispatch(updateGameDescription(game_description));
        dispatch(updateGameTitle(game_title));
        dispatch(updateGameID(game_id));

        const { data } = await axios.get(game_code_url);
        const gameCode = data;

        // console.log({ images });

        createGameCode(
          imageAssetsWithBlobUrl,
          soundAssetsWithBlobUrl,
          gameCode
        );

        dispatch(updateIsLoading(false));
        dispatch(updateIsGameGenerating(false));
        return true;
      }
    } catch (error) {
      if (error.response.status !== 404) {
        dispatch(updateIsLoading(false));
        dispatch(updateIsGameGenerating(false));
        clearInterval(intervalId);
        dispatch(
          updateGameAssetIterationAPIError(
            "An error occurred while generating the game. Please try again."
          )
        );
        return false;
      }

      let currentStatus = error.response.data.data.status.status;
      const statusOrder = [
        "in_queue",
        "code_prompting",
        "code_generating",
        "asset_prompting",
        "asset_generating",
        "final_touches",
        "done",
      ];

      dispatch(updateLoaderMessage(currentStatus));
      const currentStatusIndex = statusOrder.indexOf(currentStatus);
      let statuses = statusOrder.slice(lastIndex, currentStatusIndex + 1);
      statuses = statuses.length !== 0 ? statuses : [currentStatus];
      dispatch(updateLoaderMessage(statuses));
      lastIndex = currentStatusIndex + 1;
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <GameRender setFirstTime={setFirstTime} />
      {firstTime && (
        <TutorialScreen setFirstTime={setFirstTime}></TutorialScreen>
      )}
    </div>
  );
};

export default EditGame;
