import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import JSZip from "jszip";
import axiosInstance from "../utils/axios";

import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  closeChatIcon,
  shareCircleIcon,
  CodeCircleIcon,
  copyIcon,
  boltIcon,
  gmailIcon,
} from "../assets";

import updateAssetsDisplayType from "../utils/updateAssetsDisplayType";
import {
  gameSaveURL,
  regexToRemoveAssetsLoader,
  regexToRemoveSoundsLoader,
  saveGameCodeURL,
} from "../constants";
import { posthogCapture } from "../utils/posthogCapture";
import removePatternsFromCode from "../utils/removePatternsFromCode";

const LaunchGame = ({ onClickCloseLaunchModel }) => {
  const [isGenerateLinkOpen, setIsGenerateLinkOpen] = useState(false);

  const code = useSelector((state) => state.game.code);
  const imageAssets = useSelector((state) => state.imageAssets.imageAssets);
  const soundAssets = useSelector((state) => state.soundAssets.soundAssets);
  const gameID = useSelector((state) => state.game.gameID);
  let prompt = useSelector((state) => state.game.prompt);

  const baseURL = window.location.host;
  const gameShareURL = "https://" + baseURL + "/play/" + gameID;
  const shareTitle =
    "Dive into a World of Gaming! Discover Your Next Favorite Game! from Braincade";
  const socialMediaPlatforms = [
    {
      name: "Whatsapp",
      icon: (
        <WhatsappShareButton url={gameShareURL} title={shareTitle}>
          <WhatsappIcon size={48} round />
        </WhatsappShareButton>
      ),
    },
    {
      name: "Twitter",
      icon: (
        <TwitterShareButton url={gameShareURL} title={shareTitle}>
          <TwitterIcon size={48} round />
        </TwitterShareButton>
      ),
    },
    {
      name: "Facebook",
      icon: (
        <FacebookShareButton url={gameShareURL} title={shareTitle}>
          <FacebookIcon size={48} round />
        </FacebookShareButton>
      ),
    },
    {
      name: "Email",
      icon: (
        <EmailShareButton url={gameShareURL} subject={shareTitle}>
          <img src={gmailIcon} className="w-12 h-12" alt="gmailIcon" />
        </EmailShareButton>
      ),
    },
  ];

  useEffect(() => {
    saveCodeAPI();
  }, []);

  const saveCodeAPI = async () => {
    const formData = new FormData();

    const regexPatterns = [
      regexToRemoveAssetsLoader,
      regexToRemoveSoundsLoader,
    ];
    const game_code = removePatternsFromCode(code, regexPatterns);
    formData.append("game_id", gameID);
    formData.append("game_code", game_code);
    formData.append("type", "game");
    // formData.append("action", "GAME");
    formData.append("code_file_url", "testURL");

    try {
      const response = await axiosInstance.post(saveGameCodeURL, formData);
      // console.log({ response });
      await gameApiCall(response.data.data.game_details);
    } catch (error) {
      console.error(error);
    }
  };

  const gameApiCall = async (code_file) => {
    // const assets = convertAssetArrayToObj(assets_list);
    const assets = { images: imageAssets, sounds: soundAssets };
    const data = {
      action: "launch",
      assets: assets,
      code_file: code_file,
      game_id: gameID,
    };
    try {
      await axiosInstance.post(gameSaveURL, data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#848484] bg-opacity-50">
      <div className="w-[504px] rounded-[8px] border px-6 py-7 border-solid border-[#484E53] launch-screen-gradient">
        <div className="flex justify-between">
          <h2 className="text-2xl font-bold text-center text-white">
            Share Options
          </h2>
          <img
            src={closeChatIcon}
            onClick={() => onClickCloseLaunchModel()}
            className="cursor-pointer"
            alt="closeChatIcon"
          />
        </div>
        <div className="flex items-center justify-between mt-7">
          <div className="flex items-center">
            <img src={shareCircleIcon} alt="shareCircleIcon" />
            <div className="ml-4">
              <p className="text-white text-center text-base font-semibold tracking-[-0.32px] mb-2">
                Sharable Link
              </p>
              <ReqCredits creditCount={100} />
            </div>
          </div>
          <GenerateLinkButton setIsGenerateLinkOpen={setIsGenerateLinkOpen} />
        </div>
        {isGenerateLinkOpen && (
          <>
            <CopyInput gameShareURL={gameShareURL} />
            <div className="flex mt-5 mr-4">
              {socialMediaPlatforms.map((platform, index) => (
                <div
                  className="flex flex-col items-center w-16 mr-4"
                  key={index}
                >
                  {platform.icon} <p className="mt-2">{platform.name}</p>
                </div>
              ))}
            </div>
          </>
        )}
        <hr className="w-full h-0 stroke-[1px] stroke-[#636363] mt-7" />
        <div className="flex items-center justify-between mt-7">
          <div className="flex items-center">
            <img src={CodeCircleIcon} alt="CodeCircleIcon" />
            <div className="ml-4">
              <p className="text-white text-center text-base font-semibold tracking-[-0.32px] mb-2">
                Get Source Code (ZIP File)
              </p>
              <ReqCredits creditCount={400} />
            </div>
          </div>
          <DownloadGame />
        </div>
        <hr className="w-full h-0 stroke-[1px] stroke-[#636363] mt-7" />
        {/* <h2 className="text-base font-semibold text-white mt-7">
          Publish Game
        </h2>
        <p className="mt-2 text-[#C1C2C5] text-sm font-normal">
          Select Platform
        </p> */}
        <div className="flex justify-center mt-7 items-center">
          {/* {platforms.map((platform, index) => (
            <div className="flex flex-col items-center gap-2" key={index}>
              <img src={platform} alt="platform" />
              <ReqCredits creditCount={400} />
            </div>
          ))} */}
          <button
            className="flex items-center justify-between font-semibold whitespace-nowrap form-button"
            onClick={() => {
              window.open("https://tally.so/r/w7WDb9", "_blank");
            }}
          >
            <h1 className="w-full">
              Share Your Feedback, Get One Month Free Access!
            </h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LaunchGame;

const ReqCredits = ({ creditCount }) => {
  return (
    <div className="flex items-center">
      <img src={boltIcon} alt="boltIcon" />
      <p className="w-[76px] h-5 shrink-0 text-[#BFBFBF] text-center text-sm not-italic font-normal leading-6 ml-2">
        {creditCount} credits
      </p>
    </div>
  );
};

const CopyInput = ({ gameShareURL }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(gameShareURL).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500); // Animation duration + some buffer
    });
  };

  return (
    <div className="flex justify-between items-center px-5 mt-5 h-[60px] shrink-0 rounded-[14.932px] border-[1.493px] border-solid border-[#303030]">
      <input
        className="w-[85%] overflow-x-hidden bg-transparent outline-none"
        value={gameShareURL}
        type="text"
        readOnly
      />
      <img
        src={copyIcon}
        className={`ml-4 cursor-pointer ${isCopied ? "animate-copy" : ""}`}
        onClick={handleCopyClick}
        alt="Copy"
      />
    </div>
  );
};

const DownloadGame = () => {
  const code = useSelector((state) => state.game.code);
  const assets_list = useSelector((state) => state.game.assets_list);
  const gameCode = updateAssetsDisplayType(code);
  const handleDownload = async () => {
    posthogCapture("DOWNLOAD_SOURCE_CODE_BUTTON_CLICKED");
    const downloadCode = `<!DOCTYPE html>
    <html>
    
    <head>
      <title>Phaser Game</title>
      <script src="https://cdn.jsdelivr.net/npm/phaser@3.70.0/dist/phaser.js"></script>
      <script>
        ${gameCode}
      </script>
    </head>
    
    <body>
    </body>
    
    </html>`;
    const zip = new JSZip();
    zip.file("index.html", downloadCode);
    const assets = zip.folder("assets");
    for (let entry in assets_list) {
      try {
        const response = await fetch(assets_list[entry].url);
        const imageBlob = await response.blob();
        const fileName = assets_list[entry].name + ".png";
        assets.file(fileName, imageBlob);
      } catch (error) {
        console.error("There was an error downloading the image:", error);
      }
    }
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "game.zip");
  };

  return (
    <button
      onClick={handleDownload}
      className="flex w-[105px] h-12 justify-center items-center rounded-[8px] px-4 py-3.5 text-white text-center text-sm font-semibold leading-5 tracking-[0.15px] btn-tertiary"
    >
      Download
    </button>
  );
};

const GenerateLinkButton = ({ setIsGenerateLinkOpen }) => {
  const onClickGenerateLink = () => {
    posthogCapture("GENERATE_LINK_BUTTON_CLICKED");
    setIsGenerateLinkOpen(true);
  };

  return (
    <>
      <button
        onClick={onClickGenerateLink}
        className="flex h-12 w-[105px] justify-center items-center rounded-[8px] px-4 py-3.5 text-white text-center text-sm font-semibold leading-5 tracking-[0.15px] btn-tertiary"
      >
        Generate
      </button>
    </>
  );
};
