import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePrompt,
  updateGameDescription,
} from "../../store/features/gameSlice.js";
import useGenerateGameDescription from "../../hooks/useGameDescription.js";
import useGameData from "../../hooks/useGameData.js";
import {
  logo,
  reloadIcon,
  likeIcon,
  pencilIcon,
  generateGameIcon,
} from "../../assets/index.js";
import avatar from "../../assets/Avatar.png";
import NewHeader from "../../components/NewHeader.jsx";
import NewPromptBar from "../../components/NewPromptBar.jsx";
import { posthogCapture } from "../../utils/posthogCapture.js";

const Chat = () => {
  const generateGameDescription = useGenerateGameDescription();
  const promptValue = useSelector((state) => state.system.promptValue);
  const displayMode = useSelector((state) => state.game.displayMode);
  const dispatch = useDispatch();
  const onClickPromptSubmitBtn = () => {
    dispatch(updatePrompt(promptValue));
    generateGameDescription(promptValue, displayMode);
  };

  useEffect(() => {
    posthogCapture("PROMPT_MODIFICATION_PAGE_VIEWED");
  }, []);

  return (
    <div className="flex justify-between flex-col h-screen pt-[1%] overflow-hidden">
      {/* <Header /> */}
      <NewHeader />
      <div className="text-white">
        <div className="container w-3/4 mx-auto">
          <div className="view-chart-gradient rounded-t-xl p-4 pb-[28px] text-white mt-3">
            <ChatItem />
            <div className="flex justify-center">
              <div className="w-3/4">
                {/* <PromptBar onClickPromptBtn={onClickPromptSubmitBtn} /> */}
                <NewPromptBar onClickPromptBtn={onClickPromptSubmitBtn} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;

const ChatItem = () => {
  const generateGameDescription = useGenerateGameDescription();
  const generateGame = useGameData();

  const displayMode = useSelector((state) => state.game.displayMode);
  const prompt = useSelector((state) => state.game.prompt);
  const gameDescription = useSelector((state) => state.game.gameDescription);
  const apiError = useSelector(
    (state) => state.system.gameIdeaGenerationAPIError
  );
  const gameGenerationApiError = useSelector(
    (state) => state.system.gameGenerationAPIError
  );

  const [modifyPromptMode, setModifyPromptMode] = useState(false);
  const [modifyDescriptionMode, setModifyDescriptionMode] = useState(false);
  const [editedPrompt, setEditedPrompt] = useState(prompt);
  const [editedGameDescription, setEditedGameDescription] =
    useState(gameDescription);

  const dispatch = useDispatch();

  const onClickSubmitEditPrompt = () => {
    dispatch(updatePrompt(editedPrompt));
    setModifyPromptMode(false);
    generateGameDescription(editedPrompt, displayMode);
  };

  const onClickSubmitGameDescription = () => {
    dispatch(updateGameDescription(editedGameDescription));
    setModifyDescriptionMode(false);
  };

  const onClickRegenerate = () => {
    posthogCapture("REGENRATE_IDEA_BUTTON_CLICKED");
    generateGameDescription(prompt, displayMode);
  };

  const onClickGenerateGame = () => {
    posthogCapture("GENERATE_GAME_BUTTON_CLICKED");
    generateGame();
  };

  return (
    <div>
      <div className="px-2 mb-6">
        {modifyPromptMode ? (
          <>
            <div className="flex items-center justify-between gap-4">
              <img src={avatar} className="w-12 h-12" alt="avatar" />
              <div className="px-4 py-3 bg-[#37353A] w-[70%] rounded-[16px]">
                <input
                  type="text"
                  className="w-full bg-transparent border-none outline-none"
                  value={editedPrompt}
                  onChange={(event) => setEditedPrompt(event.target.value)}
                />
              </div>
              <button
                onClick={onClickSubmitEditPrompt}
                className="py-2 submit-gradient px-9 rounded-[8px] duration-500"
                style={{
                  opacity:
                    editedPrompt?.length === 0 || editedPrompt === prompt
                      ? "50%"
                      : "100%",
                }}
              >
                Submit
              </button>
              <button
                onClick={() => {
                  setModifyPromptMode(false);
                  setEditedPrompt(prompt);
                }}
                className="py-2 bg-[#1A1D21] px-9 rounded-[8px]"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <img src={avatar} className="w-12 h-12" alt="avatar" />
                <h1 className="text-lg">{prompt}</h1>
              </div>
              <button
                onClick={() => {
                  posthogCapture("MODIFY_PROMPT_BUTTON_CLICKED");
                  setModifyPromptMode(true);
                  setModifyDescriptionMode(false);
                  setEditedGameDescription(gameDescription);
                }}
                className="text-[#9B9C9E] px-4 py-2 bg-[#1A1D21] rounded-md flex gap-2 items-center justify-center self-end text-sm"
              >
                <img src={pencilIcon} className="w-3 h-3" alt="pencilIcon" />
                Modify
              </button>
            </div>
          </>
        )}
      </div>
      <div className="p-4 bg-[#252526] rounded-xl border border-[#1A1D21] mb-6 h-[330px]">
        <div className="flex items-start gap-4 overflow-hidden">
          <img src={logo} className="w-10 h-10 mr-4" alt="logo" />
          <div className="w-full">
            {modifyDescriptionMode ? (
              <div className="w-full">
                <textarea
                  type="text"
                  className="resize-none flex justify-start mt-2 text-base text-[#D6D6D6] bg-[#37353A] p-4 leading-6 font-medium min-h-[220px] w-[100%] outline-none rounded-[16px]"
                  value={editedGameDescription}
                  onChange={(e) => setEditedGameDescription(e.target.value)}
                ></textarea>
              </div>
            ) : (
              <textarea
                readOnly
                className="mt-2 text-lg resize-none flex justify-start text-[#9B9C9E] bg-transparent leading-6 font-medium min-h-[220px] w-[100%] outline-none "
                value={gameDescription}
              ></textarea>
            )}
          </div>
        </div>
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center gap-2">
            <button className="px-2 py-1.5 bg-[#1A1D21] text-white rounded-md flex gap-2 items-center justify-center self-end">
              <img src={likeIcon} className="w-4 h-4" alt="likeIcon" />
            </button>
            <button className="px-2 py-1.5 bg-[#1A1D21] text-white rounded-md flex gap-2 items-center justify-center self-end">
              <img
                src={likeIcon}
                className="w-4 h-4 rotate-180"
                alt="dislikeIcon"
              />
            </button>
          </div>
          <div className="flex items-center gap-4">
            {modifyDescriptionMode ? (
              <>
                <button
                  onClick={onClickSubmitGameDescription}
                  className="py-2 submit-gradient px-9 rounded-[8px] duration-500"
                  style={{
                    opacity:
                      editedGameDescription?.length === 0 ? "50%" : "100%",
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    setModifyDescriptionMode(false);
                    setEditedGameDescription(gameDescription);
                  }}
                  className="py-2 bg-[#1A1D21] px-9 rounded-[8px] duration-500"
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={onClickRegenerate}
                  className="px-4 py-2 bg-[#1A1D21] text-gray-300 rounded-md flex gap-2 items-center justify-center self-end text-sm "
                >
                  <img src={reloadIcon} className="w-3 h-3" alt="reloadIcon" />
                  Regenerate
                </button>
                <button
                  onClick={() => {
                    posthogCapture("MODIFY_IDEA_BUTTON_CLICKED");
                    setModifyDescriptionMode(true);
                    setModifyPromptMode(false);
                    setEditedGameDescription(gameDescription);
                  }}
                  className="px-4 py-2 bg-[#1A1D21] text-gray-300 rounded-md flex gap-2 items-center justify-center self-end text-sm"
                >
                  <img src={pencilIcon} className="w-3 h-3" alt="pencilIcon" />
                  Modify
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {!modifyDescriptionMode && !modifyPromptMode ? (
        <div className="flex items-center justify-between gap-4 mt-4 mb-10 mr-4">
          <div>
            {apiError ? <p className="text-red-500 ">{apiError}</p> : null}
          </div>
          <div>
            {gameGenerationApiError ? (
              <p className="text-red-500 ">{gameGenerationApiError}</p>
            ) : null}
          </div>
          <div className="flex flex-shrink-0">
            <h1 className="flex items-center self-end justify-center gap-2 px-4 py-2 text-gray-300 rounded-md">
              Estimated Credits: <span className="text-white">24</span>
            </h1>
            <button
              onClick={() => onClickGenerateGame()}
              className="px-5 py-2 bg-[#3A4CEE] text-white rounded-md flex gap-2 items-center justify-center self-end"
            >
              <img
                src={generateGameIcon}
                className="w-4 h-4"
                alt="generateGameIcon"
              />
              Generate Game
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-64"></div>
      )}
    </div>
  );
};
